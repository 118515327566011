<template>
    <main v-if="visible">

<!--        <ButtonUp />-->

        <v-dialog/>

        <div class="scrollableDiv">

            <div class="head">
                <h2 class="title">Шаг 1. Выберите города и рубрики: </h2>
                <div>
                    <Navigation v-if="!scrollMode"
                                class="navigation"
                                title="Далее"
                                :disabledForward="disabledForward"
                                @forward="toNextStep"
                                @back="toPrevStep"
                    />

                    <p v-if="calculationInProgress && getOurDatabase" class="calculationInProgress">Производим расчет...</p>
                    <div v-else-if="getOurDatabase" class="companiesCountEstimation">

                        <div v-if="enoughCompanies" class="success">
                            <img src="/images/icons/success-complete.svg" alt=""/>
                            <p><b>Все хорошо,</b> с таким количеством<br/>мы уже можем начать обзвон!</p>
                        </div>
                        <div v-else class="warning">
                            <img src="/images/icons/failure-complete.svg" alt=""/>
                            <p v-if="!enoughCompanies">
                                Минимальное число компаний для заказа &mdash; 500
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <section class="settings">
                <div class="info">

                    <div class="settingsTitle">Выбор базы</div>

                    <div class="chooseBase">
                      <div :class="[{choose: ourBase}, 'baseSelector']" @click="chooseOurDatabase">
                        <span class="cardHeading">
                          База Export-Base
                        </span>
                        <span class="cardPrice">
                          Бесплатно
                        </span>
                        <span class="cardDescription">
                          В комплекте
                        </span>
                      </div>
                      <div :class="[{ choose: clientBase }, 'baseSelector']" @click="chooseYourDatabase">
                        <span class="cardHeading">
                          Ваша база
                        </span>
                        <span class="cardPrice">
                          +5 руб/контакт
                        </span>
                        <span class="cardDescription">
                          база ваших клиентов или купленная не у нас база
                        </span>
                      </div>
                      <div :class="[{ choose: baseSelection }, 'baseSelector']"
                           @click="chooseManualDatabase">
                        <span class="cardHeading">
                          Ручной подбор базы
                        </span>
                        <span class="cardPrice">
                          +2 500 рублей
                        </span>
                        <span class="cardDescription">
                          подбор данных по вашим критериям
                        </span>
                      </div>
                    </div>
                    <div class="costAndCount">
                        <p class="foundedCount">Выбрано компаний:
                            <img v-if="calculationInProgress"
                                 src="./_img/preloader.gif"
                                 class="preloader"
                                 alt="calculating"
                            />
                            <span v-if="baseSelection"><b>{{ formatNumber(companiesCount) }}</b></span>
                            <span v-else-if="!enoughCompanies"><b>{{ formatNumber(maxCompaniesCount) }}</b>
                                <span>
                                    из <b>{{ formatNumber(maxCompaniesCount) }}</b>
                                </span>.
                            </span>
                            <span v-else><b>{{ formatNumber(companiesCount) }}</b>
                                <span>
                                    из <b>{{ formatNumber(maxCompaniesCount) }}</b>
                                </span>.
                            </span>
                        </p>
                        <p v-if="enoughCompanies" class="foundedCount">Стоимость проекта: <b>{{ formatNumber(totalCost) }} руб.</b></p>
                    </div>

                    <div class="sliderBlock" v-if="enoughCompanies && showSlider && !baseSelection">
                        <vue-slider v-model="companiesCountModel" width="inherit" height="10px" dotSize="22"
                                    :max=maxCompaniesCount :min=minCountOfCalls :interval=1 :tooltip="'always'"
                                    :contained="true" tooltipPlacement="bottom"
                                    :processStyle="{'background-color': '#91AD2C'}"
                                    :dotOptions="[{style: {'backgroundColor': '#91AD2C',
                                                 'boxShadow': 'none',
                                                 'border': '1px solid #fff',
                                                 'width': '22px',
                                                 'height': '22px'
                                      }}]">
                            <template v-slot:tooltip="{ companiesCountModel }">
                                <div class="custom-tooltip" @mousedown.stop @click.stop>
                                    <span :class="{hideTooltipText: !allowAddFirmToCalling}"
                                          @click="allowAddFirmToCalling ? setCompaniesCount(companiesCount + 1) : ''">
                                        +
                                    </span>
                                    {{ formatNumber(companiesCount) }}
                                    <span :class="{hideTooltipText: !allowDeleteFirmToCalling}"
                                        @click="allowDeleteFirmToCalling ? setCompaniesCount(companiesCount - 1) : ''">
                                        -
                                    </span>
                                </div>
                            </template>
                        </vue-slider>
                    </div>
                    <transition name="appear" mode="out-in">
                      <p v-if="clientBase" class="theirBaseFirmCount" key="client">
                        <span>Укажите количество компаний в вашей базе:</span>
                        <input type="number" :value="clientDbSize"
                               @input="handleClientDbSizeInput($event.target.value)" min="500">
                      </p>
                      <p v-if="baseSelection" class="baseToOrderFirmCount" key="baseToOrder">
                        <span>Укажите количество компаний которое необходимо обзвонить:</span>
                        <input type="number" :value="companiesCount"
                               @input="handleSetCompaniesCountByBaseToOrder(+$event.target.value)" min="500">
                      </p>
                    </transition>
                </div>
            </section>

          <transition name="appear">
            <section class="trees" v-if="ourBase" key="exportBase">
              <section class="tree geoTree">
                <h3 class="title">Куда звоним:</h3>
                <GeoTree />
              </section>
              <section class="tree sectionTree">
                <h3 class="title">Кому звоним:</h3>
                <SectionTree />
              </section>
            </section>

            <p v-if="clientBase" key="client" class="clientBaseExplanation">
              Доплата за опцию вызвана тем, что невозможно оценить контактность базы заранее.
            </p>
          </transition>
        </div>
    </main>
</template>

<script>
    import GeoTree from './GeoTree/GeoTree';
    import SectionTree from './SectionTree/SectionTree';
    import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
    import Navigation from '../../Navigation/Navigation';
    import VueSlider from 'vue-slider-component';
    import 'vue-slider-component/theme/antd.css';
    import ButtonUp from "../../../../discountOrder/components/Modals/CitiesAndRubrics/ButtonUp/ButtonUp";

    export default {
        name: 'CitiesAndRubrics',
        components: {
            ButtonUp,
            VueSlider,
            Navigation,
            GeoTree,
            SectionTree,
        },

        props: {
          visible: Boolean,
        },

        data() {
            return {
                scrollMode: false,
            };
        },

        created() {
            this.setGeoTree();
            this.setSectionTree();
        },

        computed: {
            ...mapState('advancedPromo', [
              'typeOfPackage',
              'dataBaseType',
              'clientDbSize',
            ]),

            ...mapState('advancedPromo/options', [
              'baseSelection',
              'onlineReport',
            ]),

            ...mapGetters('advancedPromo', [
                'companiesCount',
                'foundCompaniesCount',
                'calculationInProgress',
                'minCountOfCalls',
                'totalCost',
                'getOurDatabase',
            ]),

            enoughCompanies() {
              if (this.baseSelection) {
                return this.companiesCount >= this.minCountOfCalls;
              } else if (this.ourBase) {
                return this.companiesCount >= this.minCountOfCalls && this.foundCompaniesCount >= this.minCountOfCalls;
              } else {
                return this.companiesCount >= this.minCountOfCalls && this.clientDbSize >= this.minCountOfCalls;
              }
            },

            disabledForward() {
                if (!this.enoughCompanies) {
                    console.log('Y');
                    return 'disabled'
                }
                console.log('N');
                return ''
            },

            showSlider() {
              if (this.ourBase) {
                return this.foundCompaniesCount > this.minCountOfCalls;
              } else {
                return this.clientDbSize > this.minCountOfCalls;
              }
            },

            readyToNextStep() {
                return (this.enoughCompanies && !this.calculationInProgress);
            },

            ourBase() {
              return this.dataBaseType === 'exportBase' && !this.baseSelection;
            },

            clientBase() {
              return this.dataBaseType === 'client' && !this.baseSelection;
            },

            companiesCountModel: {
              get() {
                return this.companiesCount;
              },
              set(value) {
                this.setCompaniesCount(value);
              },
            },

          maxCompaniesCount() {
            if (this.ourBase) {
              return this.foundCompaniesCount;
            } else {
              return this.clientDbSize;
            }
          },

          allowAddFirmToCalling() {
              if (this.ourBase) {
                return this.companiesCount < this.foundCompaniesCount;
              } else {
                return this.companiesCount < this.clientDbSize;
              }
          },

          allowDeleteFirmToCalling() {
            return this.companiesCount > this.minCountOfCalls;
          },
        },

        methods: {
            ...mapActions('advancedPromo', [
              'calculateOrder',
            ]),

            ...mapActions('advancedPromo/geoTree', [
                'setGeoTree',
            ]),

            ...mapActions('advancedPromo/sectionTree', [
                'setSectionTree',
            ]),

          ...mapMutations('advancedPromo/options', [
              'switchAdditionalOption',
              'setAdditionalOption',
              'resetAdditionalOptions',
          ]),

            ...mapMutations('advancedPromo', [
                'setTypeOfPackage',
                'setCompaniesCount',
                'setDataBaseType',
                'setClientDbSize',
                'setFoundCompaniesCount',
                'setChoiceDatabase',
                'resetDatabase',
                'setCalculationInProgress',
            ]),

          chooseManualDatabase() {
            this.resetDatabase();
            this.resetAdditionalOptions();
            this.setChoiceDatabase(['manualDatabase', true]);
            this.handleSetBaseSelection(true);
            this.setCalculationInProgress(false);
          },

          chooseYourDatabase() {
            this.resetDatabase();
            this.resetAdditionalOptions();
            this.setChoiceDatabase(['yourDatabase', true]);
            this.handleSetDataBaseType('client');
            this.setCalculationInProgress(false);
          },

          chooseOurDatabase() {
            this.resetDatabase();
            this.resetAdditionalOptions();
            this.setChoiceDatabase(['ourDatabase', true]);
            this.handleSetDataBaseType('exportBase');
          },

            handleClientDbSizeInput(size) {
              const companyCount = +size;

              this.setClientDbSize(companyCount);

              if (
                this.companiesCount > companyCount &&
                this.companiesCount >= this.minCountOfCalls &&
                companyCount >= this.minCountOfCalls
              ) {
                this.setCompaniesCount(companyCount);
              }
            },

          handleSetDataBaseType(type) {
              this.setDataBaseType(type);

              if (this.baseSelection) {
                this.switchAdditionalOption('baseSelection');
              }

              if (this.dataBaseType === 'client') {
                // Выключаем опцию онлайн отчетности при включении базы клиента.
                if (this.onlineReport) {
                  this.switchAdditionalOption('onlineReport');
                }

                if (this.companiesCount >= this.clientDbSize) {
                  this.setCompaniesCount(this.clientDbSize);
                }
              } else {
                this.calculateOrder();
                if (this.companiesCount >= this.foundCompaniesCount) {
                  this.setCompaniesCount(this.foundCompaniesCount);
                }
              }

            if (this.companiesCount < this.minCountOfCalls) {
              this.setCompaniesCount(this.minCountOfCalls);
            }
          },

            handleSetBaseSelection(state) {
              if (state !== this.baseSelection) {
                this.switchAdditionalOption('baseSelection');
                this.setAdditionalOption(['onlineReport', false]);
                this.setDataBaseType('exportBase');
              }
            },

            handleSetCompaniesCountByBaseToOrder(count) {
                this.setCompaniesCount(count);
                this.setFoundCompaniesCount(count);
            },

            toNextStep() {
                if (this.readyToNextStep) {
                    this.changeModal('options', 'citiesAndRubrics');
                    // Пересчет здесь добавлен для корректной работы с базой на заказ.
                    this.calculateOrder();
                } else {
                    window.document.querySelector('[data-tab_button="main"]').style.zIndex = 0;
                    this.$modal.show('dialog', {
                        text: `Необходимо выбрать минимум 500 компаний для обзвона.`,
                        buttons: [
                            {
                                title: 'OK',
                                handler: () => {
                                    window.document.querySelector('[data-tab_button="main"]').style.zIndex = '';
                                    this.$modal.hide('dialog');
                                },
                            },
                        ],
                    });
                }
            },
            toPrevStep() {
                this.changeModal('introduction', 'citiesAndRubrics');
                window.MFunctions.showHeadConfigurator();
            },

            changeModal(openingModal, closingModal) {
                this.$emit('changeModal', openingModal, closingModal);
            },

            formatNumber(n) {
                return n.toLocaleString(['ru', 'en']);
            },
        },
    }
</script>

<style scoped src="../_css/modals.css"></style>
<style scoped src="../_css/reset.css"/>
<style scoped lang="less">
    .navigation {
        justify-content: flex-end;
    }

    .calculationInProgress {
        font-style: italic;
        font-weight: bold;
        color: #ff7221;
        top: 3px;
    }

    .preloader {
        width: 1em;
        margin: 0 5px;
    }

    .calculationInProgress, .companiesCountEstimation {
        height: 40px;
        margin-top: 15px;
        line-height: 15px;
    }

    .warning, .success {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        font-size: 12px;
        text-align: end;
    }

    .tree {
        width: 50%;
    }

    .trees, .vm--container.scrollable {
        margin-top: 15px;
        display: flex;
    }

    /* Внимание! Это костыль, примененный для экономии времени в условиях,
        когда надо быстро завершить задачу "Заказ по акции".
        Смысл следующий: только для заказа по акции полностью выбранная нода дерева будет иметь background.
         Для других деревьев на сайтах такая стилизация не планируется
         Решение очень хрупкое и полагается на css-классы глубоко вложенных компонентов :(
         */
    .trees /deep/ .node-view-line .tree-ul-line .checkbox-button-label .text span {
        background-color: #f3ffbd;
        padding: 2px;
    }

    .linkToAnotherAction {
        border-bottom: 1px dashed;
        cursor: pointer;
    }

    .sliderBlock {
      margin: 15px 30px 50px 30px;
    }

    .custom-tooltip {
      background-color: white;
      color: black;
      border: 1px solid #DDDDDD;
      border-radius: 5px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      top: -4px;
      width: 5em;
      height: 1.5em;

      & span {
        cursor: pointer;
        font-weight: bold;
        font-size: 15px;
      }
    }

    span.hideTooltipText {
      color: white;
      cursor: default;
    }

    .settingsTitle {
        font-size: 20px;
        font-weight: bold;
    }

    .chooseBase {
      margin: 15px 0 20px;
      display: flex;
      justify-content: space-between;

      .baseSelector {
          width: 30%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;
          min-height: 100px;
          padding: 5px 10px 10px 10px;
          border-radius: 5px;
          border: 1px solid #DDDDDD;
          cursor: pointer;

          &:hover {
              border: 1px solid #839F22;
          }

          &.choose {
              background: var(--primaryEB, #839F22);
              color: white;
              cursor: default;

              .cardPrice {
                  color: white;
              }
          }

          .cardHeading {
              font-weight: bold;
          }

          .cardPrice {
              font-size: 20px;
              font-weight: bold;
              color: var(--primaryEB, #839F22);
          }

          .cardDescription {
              font-size: 12px;
          }
      }

      .choose {
        background: linear-gradient(0deg, #a5c43a, #bbd085 70%);
        box-shadow: none;

        &:hover {
          box-shadow: none;
        }

        span:first-of-type {
          border-bottom: 1px solid transparent;
        }
      }
    }

    .appear-enter-active, .appear-leave-active {
      transition: opacity 0.3s linear;
    }

    .appear-enter, .appear-leave-to {
      opacity: 0;
    }

    .theirBaseFirmCount, .baseToOrderFirmCount {
      display: flex;
      align-items: center;
      margin: 5px 0;

      input {
        height: 25px;
        width: 60px;
        margin-left: 10px;
        background-color: #F7F7F7;
        border-radius: 5px;
        text-align: center;
      }
    }

    .baseToOrderFirmCount {
      width: 130%;
    }

    .head {
        display: flex;
        justify-content: space-between;
        height: 85px;
    }

    h2 {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: bold;
    }

    h3 {
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: bold;
    }

    .clientBaseExplanation {
      font-style: italic;
    }

    .costAndCount{
        display: flex;
        gap: 10px;
    }

    .foundedCount {
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        width: max-content;
        padding: 5px 15px;
    }
</style>
