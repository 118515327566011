<template>
    <main v-show="visible">
        <v-dialog @closed="beforeCloseModal"/>
        <div class="paymentBlock">
            <section class="billInfo">
                <h1>Ваш заказ:</h1>
                <div class="billInfoBlock">
                    <p class="billInfoColumnLeft">
                        <span>Обзвоним:</span>
                        <span>Стоимость:</span>
                        <span>Стоимость звонка:</span>
                        <span class="gap"></span>
                        <span>Обзвон:</span>
                        <span>Дозвон до ЛПР:</span>
                        <span>Сбор контактов ЛПР:</span>
                        <span>Возражения:</span>
                        <span>Работа с согласиями:</span>
                        <span :class="{recall: workWithAgreements}">Доп.прозвон:</span>
                        <span class="gap"></span>
                        <span>Отчеты:</span>
                        <span>Контроль качества:</span>
                        <span>Скрипт разговора:	</span>
                        <span>База данных:</span>
                        <span>Получение лида в CRM</span>
                    </p>
                    <p class="billInfoColumnRight">
                        <span v-if="baseSelection"><b>{{ companiesCount }} фирм</b></span>
                        <span v-else-if="dataBaseType === 'exportBase'">
                          <b>{{ companiesCount }} фирм из {{ foundCompaniesCount }}</b>
                        </span>
                        <span v-else><b>{{ companiesCount }} фирм из {{ clientDbSize }}</b></span>
                        <span><b>{{ totalCost.toLocaleString('ru') }} руб</b></span>
                        <span class="oneCallCost">{{ oneCallCost }} руб</span>
                        <span class="gap"></span>
                        <span v-if="commercialOffer" class="options chosenOption">Отправка КП</span>
                        <span v-else class="options chosenOption">Без отправки КП</span>

                        <span v-if="callContactPerson" class="options chosenOption">Связь с директором</span>
                        <span v-else class="options notChosenOption">На общих условиях</span>
                        <span v-if="decisionMakerContacts" class="options chosenOption">Уточняем прямые контакты</span>
                        <span v-else class="options notChosenOption">На общих условиях</span>

                        <span v-if="workWithObjections === 1" class="options chosenOption">До 5</span>
                        <span v-else-if="workWithObjections === 2" class="options chosenOption">До 10</span>
                        <span v-else class="options notChosenOption">Не заказано</span>
                        <span v-if="workWithAgreements" class="options chosenOption">Получение горячих клиентов</span>
                        <span v-else class="options notChosenOption">Не заказано</span>
                        <span v-if="recallFirms" class="options chosenOption">Заказан</span>
                        <span v-else class="options notChosenOption">Не заказано</span>
                        <span class="gap"></span>
                        <span v-if="onlineReport" class="options chosenOption">Онлайн</span>
                        <span v-else class="options chosenOption">С записью звонков</span>
                        <span class="options chosenOption">Проверка звонков</span>
                        <span v-if="expandScript" class="options chosenOption">Расширенный</span>
                        <span v-else class="options chosenOption">Составляем мы</span>
                        <span v-if="baseSelection" class="options chosenOption">На заказ</span>
                        <span v-else-if="dataBaseType === 'client'" class="options chosenOption">Клиента</span>
                        <span v-else class="options chosenOption">ExportBase</span>
                        <span v-if="addApplicationCRM" class="options chosenOption">Заказано</span>
                        <span v-else class="options notChosenOption">Не заказано</span>
                    </p>
                </div>
                <form method="post" target="_blank" action="/individual_commercial_offer" ref="form">
                  <input type="text" name="type" :value="typeOfPackageAdapter" hidden/>
                  <input type="text" name="additionalFilters" :value="additionalFilters" hidden/>
                  <input type="number" name="cost" :value="totalCost" hidden/>
                  <input type="number" name="callsCount" :value="companiesCount" hidden/>
                  <CommercialOfferButton @click.native="getCommercialOffer" text="Скачать коммерческое" />
                </form>
            </section>
            <section class="userInfo">
                <div class="headBackBlock">
                  <h1>Ваши комментарии:</h1>
                  <Back class="back" @back="changeModal('options', 'createOrder')"/>
                </div>
                <textarea class="cin_user_comment" alt="Минимум 3 символа" @input="inputComment" :value="userComment"
                          placeholder="Уточните цель обзвона">
                </textarea>
                <div class="userInfoBlock">
                    <p class="phone">
                        <span>Ваш телефон:</span>
                        <InputValidation :isValid="isValidPhone" :ignoreTouchedProps="ignoreTouched"
                                         insideText="+7 902 330-56-65" :value="userPhone"
                                         @input="inputPhone" mask="+{7} (000) 000-00-00"/>
                    </p>
                    <p class="email">
                        <span>Ваш e-mail:</span>
                        <InputValidation :isValid="isValidEmail" :ignoreTouchedProps="ignoreTouched"
                                         insideText="Введите эл.почту" :value="userEmail" @input="inputEmail"/>
                    </p>
                    <p class="name" style="display: none">
                        <span>Ваше имя:</span>
                        <InputValidation :isValid="isValidName" :ignoreTouchedProps="ignoreTouched"
                                         insideText="Иван" @input="inputName" :value="userName"/>
                    </p>
                    <p class="date" title="По московскому времени">
                        <span class="whenCall">Когда вам позвонить:</span>
                        <span class="select">
                        <select v-model="selectedDateIndex" @change="inputTimeAndDateRecall">
                            <option v-for="(option, index) in filteredDate" :value="index" v-bind:key="index">
                                {{ option.content }}
                            </option>
                        </select>
                        <select v-model="selectedTimeIndex" @change="inputTimeAndDateRecall">
                            <option v-for="(option, index) in filteredTime" :value="index" v-bind:key="index">
                                {{ option.content }}
                            </option>
                        </select>
                        </span>
                    </p>
                </div>
                <div class="paymentTypeBlock">
                    <span class="headOfPaymentTypeBlock">Предпочтительная оплата:</span>
                    <div class="paymentTypeInputs" title="Ваш способ оплаты">
                        <p v-bind:class="{ checkedRadio: userTypePay === 1 }" class="radioBlock cashlessBlock">
                            <input type="radio" id="cashless" name="paymentRadioButton" :value=1
                                   :checked="userTypePay === 1"
                                   @change="inputTypePay">
                            <label for="cashless">
                                <span v-bind:class="{ headOfCheckedRadio: userTypePay === 1 }">Безналичный расчет</span>
                                <span>Выставить счет без НДС</span><br/>
                              <!--Старый код, взяли для экономии времени.-->
                                <span class="add_text _mod-italic uploadRequisites _mod-inlock
                                            _requisiten_input_main_trigger">Прикрепить реквизиты</span>
                                <span class="file-loader" data-purpose="requisiten">
                                    <input class="_mod-hide _input_upload_file _requisiten_input_main" type="file"
                                           name="requisiten">
                                </span>
                              <!--Старый код, взяли для экономии времени.-->
                            </label>
                            <img src="./_img/iconCashless.png" width="55" height="36" alt="Безналичный расчет"
                                 class="cashlessImg" v-if="userTypePay === 1"/>
                        </p>
                        <p v-bind:class="{ checkedRadio: userTypePay === 0 }" class="radioBlock eMoney">
                            <input type="radio" id="electronicMoney" name="paymentRadioButton" :value=0
                                   :checked="userTypePay === 0"
                                   @change="inputTypePay">
                            <label for="electronicMoney">
                              <span v-bind:class="{ headOfCheckedRadio: userTypePay === 0 }">Банковские карты</span>
                              <span>Я.деньги, webMoney, Qiwi и др.</span>
                            </label>
                        </p>
                    </div>
                </div>
                <div class="personalDataAgreement">
                    <input type="checkbox" v-model="privacyPolicyChecked"
                           :class="[privacyPolicyChecked ? '' : 'invalid']">
                    <p>Даю согласие на обработку моего e-mail и телефона в соответствии с
                        <a class="blueLink" href="https://export-base.ru/privacy_policy" rel="noopener noreferrer"
                           target="_blank">политикой конфиденциальности ExportBase</a>
                    </p>
                </div>
                <div class="buttonPayWrapper">
                <div :class="[{hiddenButton: orderCreation}, 'buttonPay']" @click="sendRequest">
                  <span class="buttonPayText">Заказать обзвон</span>
                </div>
                <img src="./_img/preloader.gif" alt="" class="preloader" v-show="orderCreation"/>
              </div>
                <div class="arrow">
                    <img src="./_img/arrow.png" width="18" height="20" alt="разделитель"/>
                </div>
                <p class="text">
                  После отправки запроса мы свяжемся с вами, уточним детали, предложим скрипт разговора и
                  начнем обзвон. По окончании предоставим отчет и необходимые закрывающие документы.
                </p>
            </section>
        </div>
    </main>
</template>

<script>
import InputValidation from 'input-validation';
import Back from '../../Navigation/Back/Back';
import CommercialOfferButton from './../../../../commercialOffers/Button/CommercialOfferButton';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';

export default {
    name: 'CreateOrder',

    components: {
      InputValidation,
      Back,
      CommercialOfferButton,
    },

    props: {
      visible: Boolean,
    },

    methods: {
        changeModal(openingModal, closingModal) {
            this.$emit('changeModal', openingModal, closingModal);
        },

        async sendRequest() {
            this.ignoreTouched = true;

            if (this.allowCreateOrder) {
                this.orderCreation = true;
                await this.createOrder();
                this.orderCreation = false;

                window.document.querySelector('[data-tab_button="main"]').style.zIndex = 0;
                this.$modal.show('dialog', {
                    text: `<p>Заявка для заказа по акции отправлена.<br>
                              С вами свяжется менеджер и обсудит детали заказа.</p><br>
                           <p style="opacity: 0.6">Если вы хотите скачать базу без проведения обзвона,<br> то можете сделать это на сайте
                              <a href="http://export-base.ru/" target="_blank" style="color: #1B88C1">
                                export-base.ru
                              </a>
                           </p>`,
                    buttons: [
                        {
                            title: 'OK',
                            handler: () => {
                                this.beforeCloseModal();
                            },
                        },
                    ],
                });
            }
        },

        inputEmail(email) {
            this.setUserEmail(email);
            this.isValidEmail = this.emailPattern;
        },

        inputPhone(phone) {
            this.setUserPhone(phone);
            this.isValidPhone = this.phonePattern;
        },

        inputName(name) {
            this.setUserName(name);
            this.isValidName = this.namePattern;
        },

        inputTypePay(event) {
            this.setUserTypePay(Number(event.target.value));
        },

        inputComment(event) {
          this.setUserComment(event.target.value);
        },

        inputTimeAndDateRecall() {
            this.setTimeAndDateRecall();
        },

        setTimeAndDateRecall() {
            this.setTimeRecall(this.timeRecall);
            this.setTimeRecallData(this.timeRecallData);
        },

        resetState() {
          this.resetTypeOfPackage();
          this.resetUserdata();
          this.resetGeoTree();
          this.resetSectionTree();
          this.resetAdditionalOptions();
          this.ignoreTouched = false;
        },

        beforeCloseModal() {
          if (this.ignoreTouched) {
            this.$modal.hide('dialog');
            window.document.querySelector('[data-tab_button="main"]').style.zIndex = '';
            this.resetState();
            this.changeModal('introduction', 'createOrder');
            window.MFunctions.showHeadConfigurator();
          }
        },

        getCommercialOffer() {
          this.$refs.form.submit();
        },

        ...mapActions('advancedPromo/createOrder', [
          'getDateAndTimeSelector'
          ]),

        ...mapActions('advancedPromo', [
              'createOrder',
          ]),

        ...mapMutations('advancedPromo/createOrder',
          [
          'setUserEmail',
          'setUserComment',
          'setUserName',
          'setUserPhone',
          'setUserTypePay',
          'setTimeRecall',
          'setTimeRecallData',
          'setTimezoneOffset'
          ],
        ),

      ...mapMutations('advancedPromo/options', [
        'resetAdditionalOptions'
      ]),

        ...mapMutations(
          {
              resetTypeOfPackage : 'advancedPromo/resetState',
              resetUserdata: 'advancedPromo/createOrder/resetState',
              resetGeoTree: 'advancedPromo/geoTree/resetTree',
              resetSectionTree: 'advancedPromo/sectionTree/resetTree',
          }
        ),
    },

    computed: {
        emailPattern() {
            const regExpEmail = /.+@.+\..+/i;
            return regExpEmail.test(this.userEmail);
        },

        phonePattern() {
            const regExpPhone = /^[+]?[\d() -]{16}\d$/;
            return regExpPhone.test(this.userPhone);
        },

        namePattern() {
            const regExpName = /^[a-zA-Zа-яёА-ЯЁ]+$/u;
            return regExpName.test(this.userName);
        },

        ...mapState('advancedPromo/createOrder',
          [
            'timeSelectors',
            'dateSelectors',
            'userName',
            'userTypePay',
            'userComment',
            'timeRecallData',
            'timeRecall',
            'userEmail',
            'userPhone',
            'timezoneOffset'
          ]
        ),

        ...mapState('advancedPromo/options',
          [
              'expandScript',
              'onlineReport',
              'baseSelection',
              'commercialOffer',
              'addApplicationCRM',
          ]
        ),

        ...mapState('advancedPromo', [
          'typeOfPackage',
          'dataBaseType',
          'clientDbSize',
        ]),

        ...mapGetters('advancedPromo', [
          'totalCost',
          'foundCompaniesCount',
          'companiesCount',
          'costOfPromo',
          'oneCallCost',
          'callContactPerson',
          'decisionMakerContacts',
          'workWithObjections',
          'recallFirms',
          'workWithAgreements',
          'additionalFilters',
          'typeOfPackageAdapter'
          ]),

        filteredDate() {
            return this.dateSelectors.filter(i => !i.holiday);
        },

        filteredTime() {
            if (this.dateSelectors.length === 0) {
                return [];
            }

            return this.dateSelectors[this.selectedDateIndex].today ?
              this.timeSelectors.filter(i => !i.past) : this.timeSelectors;
        },

        timeRecall() {
            return `${this.filteredDate[this.selectedDateIndex].content}`+
              ` ${'в ' + this.timeInMoscowTimezone}`;
        },

        timeRecallData() {
            return `${this.filteredDate[this.selectedDateIndex].data}`+
              ` ${this.timeInMoscowTimezone}`;
        },

        timeInMoscowTimezone() {
            const localTimeArr = this.filteredTime[this.selectedTimeIndex].data.split(':');

            const localHour = localTimeArr[0];
            const hourInMoscow = localHour - this.timezoneOffset;

            return hourInMoscow + ':' + localTimeArr[1];
        },

      allowCreateOrder() {
        return this.isValidEmail && this.isValidPhone && this.privacyPolicyChecked &&
          !this.orderCreation;
      },
    },

    data() {
        return {
            isValidEmail: false,
            isValidPhone: false,
            isValidName: false,
            selectedDateIndex: 0,
            selectedTimeIndex: 0,
            privacyPolicyChecked: true,
            ignoreTouched: false,
            commercialProposal: '',
            orderCreation: false
        };
    },

    async beforeMount() {
        this.setTimezoneOffset();
        await this.getDateAndTimeSelector();
        this.setTimeAndDateRecall();
    },
};
</script>

<style scoped src="../_css/modals.css"></style>
<style scoped lang="less">
    main {
        margin: -15px 2% 0%;
    }

    .paymentBlock {
        display: flex;
    }

    .buttonPayWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .buttonPay {
        cursor: pointer;
        display: inline-flex;
        box-shadow: 0 0 1px #333;
        height: 60px;
        width: 300px;
        text-align: center;
        background: linear-gradient(0deg, rgba(250,207,2,1) 40%, rgba(252,232,0,1) 100%);
        border-radius: 5px;
        align-self: center;
        margin-bottom: 4%;
        justify-content: center;
        align-items: center;
    }

    .buttonPayText {
        display: inline-block;
        font-size: 28px;
        font-style: italic;
        font-weight: bold;
        color: #353B31;
    }

    .buttonPay:hover{
        box-shadow: 0 0 4px #333;
    }

    .hiddenButton {
      opacity: 0.3;
      cursor: default;

      &:hover {
        box-shadow: 0 0 1px #333;
      }
    }

    .billInfo, .billInfoColumnLeft, .billInfoColumnRight {
        display: flex;
        flex-direction: column;
    }

    .billInfoBlock {
        display: flex;
    }

    .gap {
        height: 20px;
    }

    .billInfoColumnRight {
        margin-left: 8%;
        width: 45%;
    }

    .billInfo {
        width: 45%;
        margin-right: 5%;
        border-right: 1px dotted #CCC;
        font-size: 14px;
    }

    h1 {
        font-size: 17px;
        font-weight: bold;
    }

    .userInfo {
        display: flex;
        flex-direction: column;
        width: 70%;
    }

    .paymentTypeInputs span {
        opacity: 0.6;
    }

    .billInfoColumnLeft span, span.headOfCheckedRadio, .userInfoBlock span, .headOfPaymentTypeBlock,
    .commercialProposal span {
        font-weight: bold;
        opacity: 1;
    }

    .billInfoColumnLeft span, .billInfoColumnRight span {
        margin: 2px 0;
    }

    .oneCallCost {
        color: #999;
    }

    textarea {
        resize: vertical;
        height: 50px;
        margin-bottom: 4%;
        background-color: #F7F7F7;
        border-radius: 5px;
        padding: 5px;
    }

    .arrow {
        align-self: center;
        margin-bottom: 1%;
    }

    .userInfoBlock {
        align-self: center;
        margin-bottom: 5%;
        width: 66%;
    }

    input[type=tel] {
        padding: 4px;
    }

    input[type=radio], input[type=checkbox] {
        align-self: flex-start;
    }

    .phone, .email, .name, .date {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .date {
        justify-content: flex-start;
        align-items: center;
        margin-top: 2.5%;
    }

    .whenCall {
        width: 25%;
    }

    .date select {
        margin-left: 2%;
        padding: 2px;
    }

    .select {
        display: flex;
        margin-left: 2.6%;
    }

    select {
        border-radius: 5px;
        background-color: #F7F7F7;
        border-width: 1px;
    }

    .paymentTypeBlock {
        display: flex;
        width: 350px;
        justify-content: space-between;
        align-self: center;
    }

    .paymentTypeInputs {
        width: 100%;
        margin-left: 5%;
    }

    .paymentTypeInputs label {
        display: flex;
        flex-direction: column;
        margin-left: 5%;
    }

    .radioBlock {
        display: flex;
        margin-bottom: 5%;
        padding: 5px;
        cursor: pointer;
        width: 100%;
    }

    .radioBlock * {
        cursor: pointer;
    }

    .checkedRadio {
        background-image: url('./_img/checkedBack.png');
        box-shadow: 0 0 0 3px #f3bb1b;
    }

    .cashlessImg {
        align-self: center;
        margin-left: 3%;
    }

    .eMoney {
        width: 93%;
    }

    .personalDataAgreement {
        display: flex;
        margin: 2% 0 4% 15%;
    }

    .personalDataAgreement p {
        margin: 0 0 0 2.5%;
    }

    .invalid {
        box-shadow: red 0 0 10px;
    }

    .text {
        font-size: 14px;
        margin: 2% 5% 5% 11%;
    }

    span.uploadRequisites {
        font-style: italic;
        border-bottom: dashed 1px black;
        align-self: flex-start;
        font-weight: bold;
        opacity: 1;
    }

    .commercialProposal {
        width: 100%;
        margin-bottom: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .commercialProposal input {
        margin-left: 2%;
    }

    .fileForm {
        width: 55%;
        overflow: hidden;
    }

    .blueLink {
        color: #1B88C1;
    }

    .options {
        padding-left: 10%;
    }

    .chosenOption {
        background: url('./_img/chosenOption.png') no-repeat -2px 0;
    }

    .notChosenOption {
        background: url('./_img/notChosenOption.png') no-repeat -2px 0;
    }

    .headBackBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5%;
      padding-right: 4px;
    }

    .phone/deep/form > table > tbody > tr > td > input,
    .email/deep/form > table > tbody > tr > td > input,
    .name/deep/form > table > tbody > tr > td > input {
        background: #F7F7F7;
        color: black;
        border-radius: 5px;
    }

    @media (max-width: 1160px) {
        .billInfo {
            margin-right: 1%;
        }
    }

    @media (max-width: 800px) {
        .billInfoColumnRight {
            margin-left: 2%;
            width: 50%;
        }

        .billInfo {
            width: 45%;
            margin-right: 2%;
        }

        .paymentTypeBlock {
            width: 83%;
        }

        .userInfoBlock[data-v-227aea7a] {
            width: 74%;
        }

        .billInfoColumnRight span, .billInfoColumnLeft span {
            padding-bottom: 1%;
        }

        .select {
            margin-left: 2.5%;
        }

        .cashlessBlock {
          width: 85%;
        }

        .eMoney {
          width: 80%;
        }
    }

    span {
      &.objections {
        margin-top: 17px;
      }

      &.recall {
        margin-top: 16px;
      }
    }

    .preloader {
      width: 32px;
      height: 32px;
      position: absolute;
      left: 85%;
      bottom: 40%;
    }
</style>
