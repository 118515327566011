<template>
  <main v-if="visible">
<!--    <ButtonUp />-->
    <v-dialog @closed="closeModal"/>

    <div class="scrollableDiv">
        <div class="head">
            <div>
                <h2 class="title">Шаг 1. Выберите города и рубрики: </h2>
                <p class="discountOrderDesc">
                    В заказе по акции будет совершен обзвон
                    <b>{{ formatNumber(fixedCompaniesCountOfOrder) }}</b> компаний.
                </p>
            </div>
            <div>
                <Navigation v-if="!scrollMode" title="Далее" class="navigation" :disabledForward="disabledForward" @forward="toNextStep" @back="toPrevStep"/>

                <p v-if="calculationInProgress && getOurDatabase" class="calculationInProgress">Производим расчет...</p>
                <div v-else-if="geoAndSectionsSettings" class="companiesCountEstimation">
                    <div v-if="enoughCompanies" class="success">
                        <img src="/images/icons/success-complete.svg" alt=""/>
                        <p>Все хорошо,<br/> с таким количеством мы уже можем начать обзвон!</p>
                    </div>
                    <div v-else class="warning">
                        <img src="/images/icons/failure-complete.svg" alt=""/>
                        <p v-if="companiesCount < 500">
                            <b>Недостаточно компаний для заказа,</b>выберите <br/>больше в конфигураторе ниже
                        </p>
                        <p v-else>
                            Недостаточно компаний для заказа по акции {{ formatNumber(fixedCompaniesCountOfOrder) }}<br/>
                            Выберите больше компаний в конфигураторе (ниже)<br/>
                            или <span @click="changePromo" class="linkToAnotherAction">акцию на {{ previousEnoughPromo }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

      <section class="settings">
        <div class="info">

            <div class="settingsTitle">Выбор базы</div>

          <div class="chooseBase">
            <BaseOption class="baseOptionExportBase" :chosen="exportBase" title="База Export-Base" footnote="бесплатно"
              @choose="chooseOurDatabase"
            >
              <template #body>
                <BaseOptionExportBase
                  :enabled="exportBase" :purchased="basePurchasedFromExportBase" @changePurchased="changePurchased"
                />
              </template>
            </BaseOption>

            <BaseOption class="baseOption" :chosen="clientBase" title="Ваша база"
              :footnote="`+ ${costOfClientBase.toLocaleString('ru')} руб`"
              @choose="chooseYourDatabase"
            >
              <template #body>база ваших клиентов или купленная не у нас база</template>
            </BaseOption>

            <BaseOption class="baseOption" :chosen="baseToOrder" title="Ручной подбор базы" footnote="+2 500 рублей"
              @choose="chooseManualDatabase"
            >
              <template #body>подбор данных по вашим критериям</template>
            </BaseOption>
          </div>

          <p v-if="geoAndSectionsSettings" class="foundedCount">Найдено компаний:
            <img v-if="calculationInProgress"
                 src="./_img/preloader.gif"
                 class="preloader"
                 alt="calculating"
            />
            <span v-else><b>{{ formatNumber(companiesCount) }}</b><span v-if="tooFewCompanies">
              из <b>{{ formatNumber(fixedCompaniesCountOfOrder) }}</b></span>
            </span>
          </p>
        </div>
      </section>

      <transition name="textAppear" mode="out-in">
        <p v-if="(exportBase && basePurchasedFromExportBase)" class="textAboutBaseToOrder">
          Желательно, чтобы скачанная база содержала достаточное количество телефонов и была сформирована в последние несколько месяцев.
          Если вы помните номер выгрузки на сайте <a href="https://export-base.ru" target="_blank"><b>export-base.ru</b></a>,
          пожалуйста, укажите его в комментариях к заказу обзвона.
        </p>
        <p v-if="clientBase" class="textAboutBaseToOrder" key="clientBaseText">
          Доплата за опцию вызвана тем, что невозможно оценить контактность базы заранее.
        </p>
        <p v-if="baseToOrder" class="textAboutBaseToOrder" key="baseSelectionText">
            Данная опция предоставляет широкий спектр возможностей по гибкому подбору баз данных
        </p>
      </transition>

      <transition name="treeAppear">
        <section class="trees" v-if="geoAndSectionsSettings">
          <section class="tree geoTree">
            <h3 class="title">Куда звоним:</h3>
            <GeoTree />
          </section>
          <section class="tree sectionTree">
            <h3 class="title">Кому звоним:</h3>
            <SectionTree />
          </section>
        </section>
      </transition>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import Navigation from '../../Navigation/Navigation';
import GeoTree from './GeoTree/GeoTree';
import SectionTree from './SectionTree/SectionTree';
import BaseOption from './BaseOption/BaseOption';
import BaseOptionExportBase from './BaseOptionExportBase/BaseOptionExportBase';
import ButtonUp from "./ButtonUp/ButtonUp";

export default {
  name: 'CitiesAndRubrics',
  components: {
    ButtonUp,
    Navigation,
    GeoTree,
    SectionTree,
    BaseOption,
    BaseOptionExportBase,
  },

  props: {
    visible: Boolean,
  },

  data() {
    return {
      scrollMode: false,
    };
  },

  created() {
    this.setGeoTree();
    this.setSectionTree();
  },

  computed: {
    ...mapState('simplePromo', [
      'typeOfPromo',
    ]),

    ...mapState('simplePromo/options', [
      'baseSelection',
      'onlineReport',
    ]),

    ...mapGetters('simplePromo', [
        'companiesCount',
        'fixedCompaniesCountOfOrder',
        'calculationInProgress',
        'exportBase',
        'basePurchasedFromExportBase',
        'clientBase',
        'baseToOrder',
        'costOfClientBase',
        'getOurDatabase',
    ]),

    tooFewCompanies() {
      return (this.companiesCount < this.fixedCompaniesCountOfOrder);
    },

    enoughCompanies() {
      return !this.tooFewCompanies;
    },

    disabledForward() {
    if (!this.enoughCompanies) {
        return 'disabled'
    }
    return ''
    },

    readyToNextStep() {
      return ((this.enoughCompanies || this.baseSelection) && !this.calculationInProgress);
    },

    previousEnoughPromo() {
      if (this.fixedCompaniesCountOfOrder === 1500) {
        return this.companiesCount < 1000 ? 500 : 1000;
      }

      return 500;
    },

    geoAndSectionsSettings() {
      return (this.exportBase && !this.basePurchasedFromExportBase);
    },
  },

  methods: {
    ...mapActions('simplePromo/geoTree', [
      'setGeoTree',
    ]),

    ...mapActions('simplePromo/sectionTree', [
      'setSectionTree',
    ]),

    ...mapActions('simplePromo', [
      'calculateOrder',
    ]),

    ...mapMutations('simplePromo', [
        'setTypeOfPromo',
        'setCompaniesCount',
        'setTypeOfBase',
        'setBasePurchased',
        'resetDatabase',
        'setChoiceDatabase',
        'setCalculationInProgress',
        'setBasePurchased',
    ]),

    ...mapMutations('simplePromo/options', [
        'switchAdditionalOption',
        'resetAdditionalOptions',
    ]),

    chooseManualDatabase() {
      this.resetDatabase();
      this.resetAdditionalOptions();
      this.setChoiceDatabase(['manualDatabase', true]);
      this.setBasePurchased(false);
      this.handleSetBaseSelection(true);
      this.setCalculationInProgress(false);
    },

    chooseYourDatabase() {
      this.resetDatabase();
      this.resetAdditionalOptions();
      this.setChoiceDatabase(['yourDatabase', true]);
      this.setBasePurchased(false);
      this.handleSetBaseType('client');
      this.setCalculationInProgress(false);
    },

    chooseOurDatabase() {
      this.resetDatabase();
      this.resetAdditionalOptions();
      this.setChoiceDatabase(['ourDatabase', true]);
      this.handleSetBaseType('exportBase');
    },

    handleSetBaseType(type) {
      this.setTypeOfBase(type);

       if (this.baseSelection) {
         this.switchAdditionalOption('baseSelection');
       }

      if (this.clientBase) {
        // Выключаем опцию онлайн отчетности при включении базы клиента.
        // #todo Перенести обработку включения опций в store из компонентов во избежание дублирования логики.
        if (this.onlineReport) {
          this.switchAdditionalOption('onlineReport');
        }

        this.setCompaniesCount(this.fixedCompaniesCountOfOrder);
      } else if (this.exportBase) {
        this.updateCompaniesCountForExportBase();
      }
    },

    handleSetBaseSelection(state) {
      this.setTypeOfBase('baseToOrder');
      this.setCompaniesCount(this.fixedCompaniesCountOfOrder);

      if (state !== this.baseSelection) {
        this.switchAdditionalOption('baseSelection');

        // Выключаем опцию онлайн отчетности при включении базы на заказ.
        // #todo Перенести обработку включения опций в store из компонентов во избежание дублирования логики.
        if (this.onlineReport) {
          this.switchAdditionalOption('onlineReport');
        }
      }
    },

    toNextStep() {
      if (this.readyToNextStep) {
        this.changeModal('options', 'citiesAndRubrics');
      } else {
        window.document.querySelector('[data-tab_button="main"]').style.zIndex = 0;
        this.$modal.show('dialog', {
          text: `Необходимо выбрать ${this.fixedCompaniesCountOfOrder} компаний для обзвона.`,
        });
      }
    },

    toPrevStep() {
      this.changeModal('introduction', 'citiesAndRubrics');
      window.MFunctions.showHeadConfigurator();
    },

    changeModal(openingModal, closingModal) {
      this.$emit('changeModal', openingModal, closingModal);
    },

    /**
     * @param n {number}
     */
    formatNumber(n) {
      return n.toLocaleString(['ru', 'en']);
    },

    changePromo() {
      this.toPrevStep();
      this.setTypeOfPromo(`promo${this.previousEnoughPromo}`);
    },

    closeModal() {
      window.document.querySelector('[data-tab_button="main"]').style.zIndex = '';
      this.$modal.hide('dialog');
    },

    changePurchased(val) {
      this.setBasePurchased(val);
      this.updateCompaniesCountForExportBase();
    },

    updateCompaniesCountForExportBase() {
      if (this.basePurchasedFromExportBase) {
        this.setCompaniesCount(this.fixedCompaniesCountOfOrder);
      } else {
        this.calculateOrder();
      }
    },
  },
};
</script>

<style scoped src="../_css/modals.css"></style>
<style scoped lang="less">
.navigation {
    justify-content: flex-end;
}

.calculationInProgress {
    font-style: italic;
    font-weight: bold;
    color: #ff7221;
    top: 3px;
}

.preloader {
    width: 1em;
    margin-left: 5px;
}

.calculationInProgress, .companiesCountEstimation {
    height: 40px;
    margin-top: 10px;
    line-height: 15px;
}

.warning, .success {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    font-size: 12px;
    text-align: end;
}

.tree {
    width: 50%;
}

.trees, .vm--container.scrollable {
    display: flex;
}

/* Внимание! Это костыль, примененный для экономии времени в условиях,
    когда надо быстро завершить задачу "Заказ по акции".
    Смысл следующий: только для заказа по акции полностью выбранная нода дерева будет иметь background.
     Для других деревьев на сайтах такая стилизация не планируется
     Решение очень хрупкое и полагается на css-классы глубоко вложенных компонентов :(
     */
.trees /deep/ .node-view-line .tree-ul-line .checkbox-button-label .text span {
    background-color: #f3ffbd;
    padding: 2px;
}

.head {
    display: flex;
    justify-content: space-between;
    height: 85px;
}

h2 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
}

h3 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
}

.discountOrderDesc {
    font-size: 14px;
}

.settingsTitle {
    font-size: 20px;
    font-weight: bold;
}

.linkToAnotherAction {
    border-bottom: 1px dashed;
    cursor: pointer;
}

.chooseBase {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 20px;
}

.baseOption {
  width: 30%;
}

.treeAppear-enter-active, .treeAppear-leave-active {
  transition: opacity 0.3s linear;
}

.treeAppear-enter, .treeAppear-leave-to {
  opacity: 0;
}

.textAppear-enter-active, .textAppear-leave-active {
  transition: opacity 0.3s linear;
}

.textAppear-enter, .textAppear-leave-to {
  opacity: 0;
}

section.settings {
  margin-bottom: 15px;
}

.foundedCount {
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    width: max-content;
    padding: 5px 15px;
}

.textAboutBaseToOrder {
  font-size: 14px;
  height: 42px;
}
</style>
