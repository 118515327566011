<template>
  <section class="info">
    <div class="_tool_nodefault info__item" data-tool_name="openFaqAndScroll">
      <span>Как сделать заказ?</span>
    </div>
    <div class="_tool info__item" data-tool_name="openCallExample">
      <span>Примеры отчетов и договоров</span>
    </div>
    <div class="info__item _clientFeedback">
      <span>Заказать обратный звонок</span>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'InfoPanel',
  };
</script>

<style scoped>
  .info{
    display: flex;
    justify-content: space-around;
    margin: 30px 0;
  }

  .info__item{
    border-radius: 8px;
    background: #EBE9D8;
    padding: 8px 15px;
    cursor: pointer;
    transition: .2s;
    font-size: 16px;
  }

  .info__item:hover{
    background: #EBE9B6;
    box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
  }
</style>

