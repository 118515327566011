<template>
    <div class="back" @click="back">
        <img src="./_img/back.png" class="backIcon" alt="Вперед" />
        <span v-if="withTitle" class="backTitle">Назад</span>
    </div>
</template>

<script>
    export default {
        name: "Back",
        props: {
            withTitle: {
                type: Boolean,
                default: true,
            },
        },

        methods: {
            back() {
                this.$emit('back');
            },
        },
    }
</script>

<style scoped>
    .back {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .backTitle {
        margin-left: 5px;
        font-size: 14px;
        font-style: italic;
        font-weight: bold;
        color: #636363;
    }

    .backIcon {
        width: 24px;
    }
</style>
