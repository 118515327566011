<template>
<div class="baseOptionExportBase" :class="{disabled: !enabled}">
  <div class="option" :class="{chosen: !purchased}" @click="changePurchased(false)">
    <span class="tickWrapper">
      <span class="tick"></span>
    </span>
    <span class="name">выберите города и рубрики</span>
  </div>
  <div class="option" :class="{chosen: purchased}" @click="changePurchased(true)">
    <span class="tickWrapper">
      <span class="tick"></span>
    </span>
    <span class="name">
        скачана с
        <a @click="clickLink" class="exportBaseLink" href="https://export-base.ru" target="_blank">export-base.ru</a>
    </span>
  </div>
</div>
</template>

<script>
export default {
  name: "BaseOptionExportBase",
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    purchased: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    changePurchased(newVal) {
      if (!this.enabled || this.purchased === newVal) {
        return;
      }
      this.$emit('changePurchased', newVal);
    },

    clickLink(e) {
        if (!this.enabled) {
            e.preventDefault();
            this.changePurchased(false);
        }
    },
  },
}
</script>

<style scoped lang="less">
.baseOptionExportBase {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:first-child {
    margin-bottom: .3em;
  }

  .tickWrapper {
    border: 1px solid #eee;
    border-radius: 1.5em;
    width: 1.3em;
    height: 1.3em;
    margin-right: 0.3em;

    .tick {
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      flex-shrink: 0;
      border: 1px solid #ccc;
      border-radius: 1.5em;
      background: #ccc;
    }
  }


}

.option.chosen {
  cursor: default;
  .tick {
    background: var(--primaryEB, #839F22);
  }
}

.baseOptionExportBase.disabled {
  .option, option.chosen {
    cursor: inherit;
  }
}

.exportBaseLink {
    text-decoration: none;
    background-color: white;
    color: var(--primaryEB, #839F22);
    padding: 0 3px;
    border-radius: 3px;
}

</style>
