<template>
    <div class="mainConfigurator" data-tab_panel="main">
      <CommercialOffers/>
      <section class="switchWithInfo">
          <h2>Настройте ваш обзвон</h2>
        <CommercialOfferButton text="Скачать КП" @click.native="showModal"/>
      </section>
      <Introduction
          :allowTransition="discountSections['allowTransition']"
          :visible="introductionVisible"
          @disallowTransition="disallowTransition"
          @toNextStep="toNextStep"
      />
      <Forward @forward="toNextStep" title="Далее к выбору базы" size="xl" v-if="showFirstNextButton"/>
      <info-panel v-if="introductionVisible" />
      <DiscountOrder @changeModal="changeModal"/>
      <AdvancedPromo />
    </div>
</template>

<script>
    import DiscountOrder from './components/discountOrder/DiscountOrder';
    import AdvancedPromo from './components/advancedPromo/AdvancedPromo';
    import CommercialOffers from './components/commercialOffers/Modal/CommercialOffers';
    import CommercialOfferButton from './components/commercialOffers/Button/CommercialOfferButton';
    import Forward from "./components/discountOrder/components/Navigation/Forward/Forward";
    import Introduction from "./components/Introduction/Introduction";
    import {mapActions, mapState} from "vuex";
    import InfoPanel from "./components/InfoPanel/InfoPanel";

    export default {
        name: 'MainConfigurator',

        data() {
            return {
              typeOfPromo: 'simple'
            };
        },

        components: {
          InfoPanel,
          DiscountOrder,
          AdvancedPromo,
          CommercialOffers,
          CommercialOfferButton,
          Forward,
          Introduction,
        },

        computed: {
          ...mapState('sectionsNavigation', [
              'introductionVisible',
              'discountSections',
          ]),

          ...mapState('advancedPromo', [
              'typeOfPackage',
          ]),

          showFirstNextButton() {
            return this.introductionVisible;
          },
        },

        methods: {
          ...mapActions('sectionsNavigation', [
              'hideSection',
              'openSection',
          ]),

          setTypeOfPromo(type) {
            this.typeOfPromo = type;
          },

          showModal() {
            window.document.querySelector('[data-tab_button="main"]').style.zIndex = 0;
            this.$modal.show('commercialOffers');
          },

          toNextStep() {
            if (!this.typeOfPromo) {
              window.document.querySelector('[data-tab_button="main"]').style.zIndex = 0;
              this.$modal.show('dialog', {
                text: `Необходимо выбрать тип акции.`,
                buttons: [
                  {
                    title: 'OK',
                    handler: () => {
                      this.$modal.hide('dialog');
                      window.document.querySelector('[data-tab_button="main"]').style.zIndex = '';
                    },
                  },
                ],
              });
            } else {
              this.changeModal('citiesAndRubrics', 'introduction');
              window.MFunctions.hideHeadConfigurator();
            }
          },

          changeModal(openingModal, closingModal) {
            this.openSection(openingModal);
            this.hideSection(closingModal);
            window.document.querySelector('.configurator_wrapper').style.minHeight = 0;

            if (openingModal === 'createOrder') {
              window.MFunctions.reachMetrikaGoal('config');
            }
          },

          disallowTransition() {
            this.allowTransition = false;
          },
        },
    };
</script>

<style scoped lang="less">
  .switchWithInfo {
    display: flex;
    justify-content: space-between;
    max-height: 200px;

    h2 {
      font-size: 24px;
      font-weight: 700;
      align-self: center;
    }
  }

  .manual {
    border-bottom: 1px black dashed;
    cursor: pointer;
  }

  .mainConfigurator * {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
  }

  .actualization-link{
    text-decoration: none;
  }
</style>
