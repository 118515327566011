const getters = {
    commonFiltersData(state, getters) {
        return {
            send_commercial_offer: getters.sendCommercialOffer,
            advanced_script: getters.expandScript,
            online_reporting: getters.onlineReport,
            db_type: getters.dbType,
            client_db_size: getters.clientDbSize,
            non_standard_database: getters.baseSelection,
            application_CRM: getters.addApplicationCRM,
            commercial_offer_CRM: getters.addCommercialOfferCRM,
        };
    },

    // Дубляж по причине разного наименования переменных.

    // Если когда нибудь создание заказов и все пересчеты будут оперировать конфигурацией на сервере,
    // вместо отправки с клиента, то можно удалять это.
    additionalFilters(state, getters) {
        return JSON.stringify({
            sendCommercialOffer: getters.sendCommercialOffer,
            advancedScript: getters.expandScript,
            applicationCRM: getters.addApplicationCRM,
            onlineReporting: getters.onlineReport,
            nonStandardDatabase: getters.baseSelection,
            baseType: getters.dbType,
            commercialOfferCRM: getters.addCommercialOfferCRM,
        });
    },

    getOptions(state, getters) {
        return [
            getters.addApplicationCRM,
            getters.expandScript,
            getters.sendCommercialOffer,
            getters.onlineReport,
            getters.baseSelection,
            getters.addCommercialOfferCRM,
        ];
    },

    getOptionsForYourAndManualDatabase(state, getters) {
        return [
            getters.addApplicationCRM,
            getters.expandScript,
            getters.sendCommercialOffer,
        ];
    },

    filtersData(state, getters) {
        return {
            ...getters['commonFiltersData'],
            count_firm_for_calling: getters.companiesCount,
        };
    },

    filtersDataOrderCalculation(state, getters) {
        return {
            ...getters['commonFiltersData'],
            count_firm_for_call: getters.companiesCount,
        };
    },

    // неизменяемый объект, передаваемый на сервер для совместимости со старым кодом на сервере
    lastConfigurationData() {
        return {
            total_count: 0,
            total_cost: 0,
            count_firm_for_calling: 0,
            one_firm_cost: 0,
        };
    },

    userInputData(state) {
        return {
            cin_user_phone: state.createOrder.userPhone,
            cin_user_email: state.createOrder.userEmail,
            cin_user_time_recall: state.createOrder.timeRecall,
            cin_user_time_recall_data: state.createOrder.timeRecallData,
            cin_user_comment: state.createOrder.userComment,
            cin_user_type_pay: state.createOrder.userTypePay,
            cin_user_requisiten: state.createOrder.filename,
            cin_user_timezone_offset: state.createOrder.timezoneOffset
        };
    },

    callContactPerson(state) {
        return state.typeOfPackage === 'low' ? 0 : 2;
    },

    workWithAgreements(state) {
        return state.typeOfPackage === 'large' ? 1 : 0;
    },

    workWithObjections(state) {
        switch (state.typeOfPackage) {
            case 'low': return 0;
            case 'medium': return 1;
            case 'large': return 2;
        }
    },

    decisionMakerContacts(state) {
        return state.typeOfPackage === 'low' ? 0 : 1;
    },

    companiesCount(state) {
        return state.companiesCount;
    },

    foundCompaniesCount(state) {
        return state.options.baseSelection ? state.companiesCount : state.foundCompaniesCount;
    },

    clientDbSize(state) {
      return state.clientDbSize;
    },

    recallFirms(state) {
        return state.typeOfPackage === 'large' ? 1 : 0;
    },

    minCountOfCalls() {
        return 500;
    },

    costOfPromo(state, getters) {
        return state.companiesCount * getters.oneCallCost;
    },

    oneCallCost(state) {
        switch (state.typeOfPackage) {
            case 'low': return 35;
            case 'medium': return 52;
            case 'large': return 58;
        }
    },

    totalCost(state, getters) {
        return getters.costOfPromo + getters.additionalCost + getters.additionalCostByClientBase - getters.getSaleOptionCost;
    },

    additionalCost(state) {
        let cost = 0;
        for (const optionName in state.options.costs) {
            if (state.options[optionName]) {
                cost += state.options.costs[optionName];
            }
        }

        return cost;
    },

    getSaleOptionCost(state) {
        let cost = 0;
        let countOption = 0;
        let sale;

        for (const optionName in state.options.costs) {
            if (state.options[optionName]) {
                cost += state.options.costs[optionName];
                countOption += 1;
            }
        }

        let percentSale = 0;

        if (countOption === 2) {
            percentSale = 3;
        } else if (countOption === 3) {
            percentSale = 5;
        } else if (countOption >= 4) {
            percentSale = 10;
        }

        sale = (cost / 100 * percentSale);

        return Math.round(sale / 100) * 100;
    },

    additionalCostByClientBase(state) {
        if (state.dataBaseType === 'client') {
            return state.companiesCount * state.additionalCostByClientBase;
        }

        return 0;
    },

    expandScript(state) {
        return state.options.expandScript ? 1 : 0;
    },

    onlineReport(state) {
        return state.options.onlineReport ? 1 : 0;
    },

    baseSelection(state) {
        return state.options.baseSelection ? 1 : 0;
    },

    addApplicationCRM(state) {
        return state.options.addApplicationCRM ? 1 : 0;
    },

    addCommercialOfferCRM(state) {
        return state.options.addCommercialOfferCRM ? 1 : 0;
    },

    sendCommercialOffer(state) {
        return state.options.commercialOffer ? 1 : 0;
    },

    calculationInProgress(state) {
        return state.calculationInProgress;
    },

    orderSettingsUpdated(state) {
        return state.orderSettingsUpdated;
    },

    typeOfPackage(state) {
        return state.typeOfPackage;
    },

    dbType(state, getters) {
        return state.dataBaseType === 'exportBase' && !getters.baseSelection ? 0 : 1;
    },

    allowSwitchOnlineReport(state, getters) {
        return state.dataBaseType === 'exportBase' && !getters.baseSelection;
    },

    allowSwitchBaseSelection(state, getters) {
        return state.dataBaseType === 'exportBase' && !getters.onlineReport;
    },

    getManualDatabase(state) {
        return state.manualDatabase;
    },

    getOurDatabase(state) {
        return state.ourDatabase;
    },

    getYourDatabase(state) {
        return state.yourDatabase;
    },

    // Преобразование для работы с коммерческим предложением.
    // Если привести названия пакетов на бэкенде к единому, то это можно удалить.
    typeOfPackageAdapter(state) {
        switch (state.typeOfPackage) {
            case 'low':
                return 'package19';
            case 'medium':
                return 'package30';
            case 'large':
                return 'package40';
        }
    },
};

export default getters;
