<template>
    <div>
        <div class="searchBar searchWrapper">
            <SearchBar class="sectionSearchBar"
               width="auto"
               placeholder="Поиск рубрики"
               @input="updateSearch"
               @reset="resetSearch"
            />
            <button class="selectOrDropAll"
                    @click="selectOrDropAllHandler">
                {{ selectOrDropAllText }}
            </button>
        </div>

        <div v-if="isSearchMode">
            <span v-if="emptySearchResult"
                  class="emptySearch">Ничего не найдено</span>
            <TreeComponent v-else
               :node="getSectionSearchTree.getRoot()"
               :innerCheckboxMode="['dark']"
               @expand-node="expandSectionSearchNode"
               @choose-node="chooseSearchNode"
            />
        </div>
        <TreeComponent v-else
            :node="getSectionTree.getRoot()"
            :innerCheckboxMode="['dark']"
            @expand-node="expandSectionNode"
            @choose-node="chooseNode"
        />
    </div>
</template>

<script>
    import {mapGetters, mapMutations, mapActions,} from 'vuex'
    import {TreeComponent,} from 'tree'
    import SearchBar from 'searchbar';
    export default {
        name: "SectionTree",
        components: {
            TreeComponent,
            SearchBar,
        },

        computed: {
            ...mapGetters('advancedPromo/sectionTree', [
                'getSectionTree',
                'getSectionSearchTree',
                'isSearchMode',
                'emptySearchResult',
                'selectOrDropAllText',
            ]),
        },

        methods: {
            ...mapActions('advancedPromo',[
                'calculateOrder',
            ]),
            ...mapMutations('advancedPromo/sectionTree', [
                'expandSectionNode',
                'chooseSectionNode',
                'expandSectionSearchNode',
                'resetSearch',
            ]),
            ...mapActions('advancedPromo/sectionTree', [
                'chooseSectionSearchNode',
                'selectOrDropAll',
                'updateSearch',
            ]),
            chooseNode(nodeDesc) {
                this.chooseSectionNode(nodeDesc);
                this.calculateOrder();
            },
            chooseSearchNode(nodeDesc) {
                this.chooseSectionSearchNode(nodeDesc);
                this.calculateOrder();
            },
            selectOrDropAllHandler() {
                this.selectOrDropAll();
                this.calculateOrder();
            },
        },
    }
</script>

<style scoped src="../_css/SearchBlock.css"></style>

<style scoped>
    .searchWrapper {
        display: flex;
        align-items: center;
    }

    .sectionSearchBar {
        flex-grow: 1;
    }

    .selectOrDropAll {
        display: inline-block;
        min-width: 120px;
        height: 22px;
        padding: 0 10px;
        border-radius: 2px;
        border: none;
        outline: none;
        margin-left: 10px;
        background-image: url(_img/select_or_drop_all.png);
        color: #ffffff;
        font-style: italic;
        cursor: pointer;
    }
</style>
