import formurlencoded from 'form-urlencoded';

const API_PREFIX = '/api';

export default {
    getDateAndTimeSelector() {
        const localTime = new Date();

        //  Смещение относительно Московского часового пояса будет положительным для местного часового пояса,
        //  находящегося западнее часового пояса UTC и отрицательным — восточнее (поэтому минус перед скобкой).
        //  И вычитаем три, чтобы получить смещение относительно Москвы.
        const timezoneOffset = - (localTime.getTimezoneOffset() / 60) - 3;

        const params = {
            "timezone_offset": timezoneOffset,
        };

        return fetch(`${API_PREFIX}/date_and_time_selectors`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json());
    },

    async getGeoTree() {
        return await fetch(`${API_PREFIX}/geo_unit`, {method: 'GET'})
            .then(response => response.json());
    },

    async getSectionTree() {
        return await fetch(`${API_PREFIX}/section`, {method: 'GET'})
            .then(response => response.json());
    },

    async createOrder(sectionData, citiesData, filtersData, userInfoData, userName,
                      companiesCount, countForCalling, typeOfPackage, totalCost) {
        let orderId = 0;

        let data = {
            ajax: 'go_pay',
            sections_data: JSON.stringify(sectionData),
            cities_data: JSON.stringify(citiesData),
            filters_data: JSON.stringify(filtersData),
            user_input_data: JSON.stringify(userInfoData),
            user_name: userName,
            count_firm_for_calling: countForCalling,
            total_count: companiesCount,
            beznal: {},
            kp_file: '',
            type_of_promo: null,
            type_of_package: typeOfPackage,
        };

        let response = await fetch(
            `/pages/main/controller/main.php`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
                body: formurlencoded(data),
            }
        );

        if (response.ok) {
            orderId = await response.json();
            window.MGoogle.reportConversion(totalCost, orderId);
            window.$('._load_file[name="id_file"]').val(orderId);
            window.$('._load_file[type="submit"]').click();
            window.MFunctions.reachMetrikaGoal('sell_mail');
            window.MFunctions.dataLayerCallPush(orderId, totalCost);
        } else {
            console.error('CODE_ERROR :: go pay error');
        }

        return orderId;
    },

    /**
     * @param orderSettings {Object}
     * @returns {Promise<number>} - количество компаний, рассчитанное по настройкам заказа
     */
    async calculateCompaniesCount(orderSettings) {
        orderSettings = formurlencoded(orderSettings);
        const order = await fetch('/pages/main/controller/main.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
            body: orderSettings,
        }).then(res => res.json());

        return order.max_count || 0;
    }
};
