<template>
<div :class="[{ chosen: chosen }, 'baseOption']" @click="choose">
  <span class="title">{{ title }}</span>
  <span class="body">
    <slot name="body"></slot>
  </span>
  <span class="footnote">{{ footnote }}</span>
</div>

</template>

<script>
export default {
  name: "BaseOption",
  props: {
    chosen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    footnote: {
      type: String,
      required: true,
    },
  },

  methods: {
    choose() {
      if (this.chosen) {
        return;
      }
      this.$emit('choose');
    }
  },
}
</script>

<style scoped lang="less">
.baseOption {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100px;
  padding: 5px 10px 10px 10px;
  border: 1px solid #999;
  border-radius: 5px;
  background: #cacaca;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 10px 5px #f4ecd9;
  }

  .title {
    font-weight: bold;
    border-bottom: 1px dashed black;
  }

  .body {
    line-height: 1;
    font-style: italic;
    margin-top: 0.6em;
    opacity: .5;
  }

  .footnote {
    align-self: flex-end;
    margin-top: auto;
    font-weight: bold;
    font-size: .9em;
    opacity: .5;
  }
}

.baseOption.chosen {
  border: 5px solid #999;
  background: linear-gradient(0deg, #a5c43a, #bbd085 70%);
  cursor: default;
  &:hover {
    box-shadow: none;
  }

  .title {
    border-bottom: 1px solid transparent;
  }

  .body {
    opacity: 1;
  }

  .footnote {
    opacity: 1;
  }
}
</style>
