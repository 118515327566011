<template>
    <div v-bind:class="promoClass" @click="choseTypeOfPackage">
        <div class="promo" @dblclick="$emit('toNextStep')">
            <div class="headOfPromo">
              <p class="promoHeaderNumber">{{ oneCallCost }}<span class="rubleSign"> &#8381;</span></p>
                <p class="promoHeaderText">за результативный звонок</p>
            </div>
            <section class="bodyOfPromo">
                <div class="costOfPromoBlock">
                    <span class="costOfPromo">От {{ price }} &#8381;</span>
                    <p class="costOfPromoText">за 500 компаний</p>
                </div>
                <div class="promoProperty" v-if="oneCallCost === '39'">
                    <p class="tickSmall">База контактов бесплатно</p>
                    <p class="tickSmall">Полная отчетность (xls)</p>
                    <p class="tickSmall">Записи звонков в .mp3</p>
                    <p class="tickSmall">Контроль качества звонков</p>
                    <p class="tickSmall">Сбор E-mail для отправки КП</p>
                </div>
                <div class="promoProperty" v-if="oneCallCost === '60'">
                  <p class="tickSmall">База контактов бесплатно</p>
                  <p class="tickSmall">Полная отчетность (xls)</p>
                  <p class="tickSmall">Записи звонков в .mp3</p>
                  <p class="tickSmall">Контроль качества звонков</p>
                  <p class="tickSmall">Сбор E-mail для отправки КП</p>
                  <p class="tickSmall">Сбор прямых контактов</p>
                  <p class="tickSmall">Дозвон до ЛПР</p>
                  <p class="tickSmall">Отработка до 5 возражений/вопросов</p>
                </div>
                <div class="promoProperty" v-if="oneCallCost === '65'">
                  <p class="tickSmall">База контактов бесплатно</p>
                  <p class="tickSmall">Полная отчетность (xls)</p>
                  <p class="tickSmall">Записи звонков в .mp3</p>
                  <p class="tickSmall">Контроль качества звонков</p>
                  <p class="tickSmall">Сбор E-mail для отправки КП</p>
                  <p class="tickSmall">Сбор прямых контактов</p>
                  <p class="tickSmall">Дозвон до ЛПР</p>
                  <p class="tickSmall">Отработка до 10 возражений/вопросов</p>
                  <p class="tickSmall">Мгновенное получение горячих клиентов</p>
                  <p class="tickSmall">Доп.прозвон</p>
                </div>

              <div class="promoProperty off" v-if="oneCallCost === '39'">
                <p class="modeSmallOption">Сбор прямых контактов</p>
                <p class="modeSmallOption">Дозвон до ЛПР</p>
                <p class="modeSmallOption">Отработка возражений/вопросов</p>
                <p class="modeSmallOption">Мгновенное получение горячих клиентов</p>
                <p class="modeSmallOption">Доп.прозвон</p>
              </div>
              <div class="promoProperty off" v-if="oneCallCost === '60'">
                <p class="modeSmallOption">Мгновенное получение горячих клиентов</p>
                <p class="modeSmallOption">Доп.прозвон</p>
              </div>
              <div v-if="chosen" @click="$emit('toNextStep')" class="chosenButton">
                <span class="call-button-prime">ВЫБРАНО</span>
              </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Promotion',

    props: {
        typeOfPackage: [String, null],
        oneCallCost: [String, Number],
        price: [String, Number],
        chosen: Boolean,
        mode: String,
        reducePromoHeight: Boolean,
    },

    computed: {
      promoClass() {
          return {
              chosenPromoCard: this.chosen,
              promoCard: true,
          };
      },
    },

    methods: {
        choseTypeOfPackage() {
            this.$emit('changeTypeOfPackage');
        },
    },
};
</script>

<style scoped lang="less">
    .promoCard {
      position: relative;
      width: 100%;
      height: 524px;
      background: url('_img/background.png') center bottom no-repeat;
      box-sizing: content-box;
      border: 1px solid #DDDDDD;
      margin: 2px;
      border-radius: 5px;
      max-width: 250px;
      cursor: pointer;
    }

    .chosenPromoCard, .promoCard:hover {
      border: 3px solid var(--primaryEB, #839F22);
      margin: 0px;
    }

    .promo {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: linear-gradient(white, #f6ff84a3);
        backdrop-filter: blur(2px);
    }

    .promo * {
        font-size: 16px;
        line-height: 16px;
    }

    .promoHeaderNumber {
        font-size: 48px;
        line-height: 48px;
    }

    .headOfPromo {
        width: calc(100% - 24px);
        top: -3px;
        left: -3px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        padding-bottom: 22px;
        padding: 15px;
        border-radius: 3px;
        color: white;
        background: var(--primaryEB, #839F22);
    }

    .bodyOfPromo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 22px;
    }

    .costOfPromoBlock {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin-bottom: 18px;
        font-size: 15px;

        p {
          font-size: 13px;
          margin-top: 5px;
        }
    }

    .costOfPromo {
        padding: 5px;
        font-size: 16px;
        border-radius: 5px;
        border: 1px solid var(--primaryEB, #839F22);
    }

    .costOfPromoText {
        color: #aaa;
    }

    .promoProperty {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 15px;
    }

    .promoProperty p {
        margin: 3px 0;
        padding-left: 35px;
    }

    .lprProperty {
        display: flex;
        flex-direction: column;
        margin-bottom: 22px;
        height: 70px;
        align-self: flex-start;
    }

    .lprPropertyPromo40 {
        display: flex;
        flex-direction: column;
        height: 36px;
        align-self: flex-start;
    }

    .lprProperty p {
        margin: 3px 0;
        padding: 0 0 0 37px;
    }

    .lprPropertyPromo40 p {
      margin: 3px 0;
      padding: 0 11px 0 37px;
    }

    .tickSmall, .modeBigOption {
        background: url('/images/icons/green-check.svg') no-repeat 15px 0;
    }

    .modeBigOption, .costOfPromoBlock, .promoHeaderNumber, .promoHeaderText {
        font-weight: bold;
    }

    .modeSmallOption {
        background: url('/images/icons/red-cross.svg') no-repeat 13px 0;
    }

    .rubleSign {
      font-size: 48px;
    }
</style>
