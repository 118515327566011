<template>
  <section class="info">
    <div class="_tool_nodefault info__item" data-tool_name="openFaqAndScroll">
      <span>Как сделать заказ?</span>
    </div>
    <div class="_tool info__item" data-tool_name="openCallExample">
      <span>Примеры отчетов и договоров</span>
    </div>
    <div class="info__item _clientFeedback">
      <span>Заказать обратный звонок</span>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'InfoPanel',
  };
</script>

<style scoped>
  .info{
    display: flex;
    justify-content: center;
    gap: 30px;
    margin: 30px 0 15px;
  }

  .info__item{
    cursor: pointer;
    color: var(--primaryEB, #839F22);

  }
</style>

