<template>
    <div class="mainConfigurator" data-tab_panel="main">
      <CommercialOffers/>
      <section class="switchWithInfo">
        <section class="switch">
          <h2>Настройте ваш обзвон</h2>
          <CommercialOfferButton @click.native="showModal"/>
        </section>
        <Forward @forward="toNextStep" title="Далее к выбору базы" v-if="showFirstNextButton"/>
      </section>
      <Introduction
          :allowTransition="discountSections['allowTransition']"
          :visible="introductionVisible"
          @disallowTransition="disallowTransition"
          @toNextStep="toNextStep"
      />
      <info-panel v-if="introductionVisible" />
      <DiscountOrder @changeModal="changeModal"/>
      <AdvancedPromo />
    </div>
</template>

<script>
    import DiscountOrder from './components/discountOrder/DiscountOrder';
    import AdvancedPromo from './components/advancedPromo/AdvancedPromo';
    import CommercialOffers from './components/commercialOffers/Modal/CommercialOffers';
    import CommercialOfferButton from './components/commercialOffers/Button/CommercialOfferButton';
    import Forward from "./components/discountOrder/components/Navigation/Forward/Forward";
    import Introduction from "./components/Introduction/Introduction";
    import {mapActions, mapState} from "vuex";
    import InfoPanel from "./components/InfoPanel/InfoPanel";

    export default {
        name: 'MainConfigurator',

        data() {
            return {
              typeOfPromo: 'simple'
            };
        },

        components: {
          InfoPanel,
          DiscountOrder,
          AdvancedPromo,
          CommercialOffers,
          CommercialOfferButton,
          Forward,
          Introduction,
        },

        computed: {
          ...mapState('sectionsNavigation', [
              'introductionVisible',
              'discountSections',
          ]),

          ...mapState('advancedPromo', [
              'typeOfPackage',
          ]),

          showFirstNextButton() {
            return this.introductionVisible;
          },
        },

        methods: {
          ...mapActions('sectionsNavigation', [
              'hideSection',
              'openSection',
          ]),

          setTypeOfPromo(type) {
            this.typeOfPromo = type;
          },

          showModal() {
            window.document.querySelector('[data-tab_button="main"]').style.zIndex = 0;
            this.$modal.show('commercialOffers');
          },

          toNextStep() {
            if (!this.typeOfPromo) {
              window.document.querySelector('[data-tab_button="main"]').style.zIndex = 0;
              this.$modal.show('dialog', {
                text: `Необходимо выбрать тип акции.`,
                buttons: [
                  {
                    title: 'OK',
                    handler: () => {
                      this.$modal.hide('dialog');
                      window.document.querySelector('[data-tab_button="main"]').style.zIndex = '';
                    },
                  },
                ],
              });
            } else {
              this.changeModal('citiesAndRubrics', 'introduction');
              window.MFunctions.hideHeadConfigurator();
            }
          },

          changeModal(openingModal, closingModal) {
            this.openSection(openingModal);
            this.hideSection(closingModal);
            window.document.querySelector('.configurator_wrapper').style.minHeight = 0;

            if (openingModal === 'createOrder') {
              window.MFunctions.reachMetrikaGoal('config');
            }
          },

          disallowTransition() {
            this.allowTransition = false;
          },
        },
    };
</script>

<style scoped lang="less">
  .switchWithInfo {
    display: flex;
    justify-content: space-between;
    max-height: 200px;
    padding: 2% 2.7%;

    .switch {
      flex-basis: 65%;

      h2 {
        font-size: 23px;
        font-weight: 700;
      }

      .images {
        display: flex;
        justify-content: space-between;
        width: 80%;
        min-width: 418px;
        margin: 3% 0 5%;

        picture {
          position: relative;
        }

        img {
          cursor: pointer;
          border-radius: 15px;
          border: solid 3px rgba(151,151,151,1);
          &:hover {
            box-shadow: 0 0 10px 5px rgb(244, 236, 217);
          }
        }

        .picked {
          &:after {
            content: "";
            width: 15px;
            height: 15px;
            position: absolute;
            z-index: -1;
            bottom: 0;
            left: 30px;
            transform: translateY(35%) rotate(45deg);
            -moz-transform: translateY(35%) rotate(45deg);
            -ms-transform: translateY(35%) rotate(45deg);
            -webkit-transform: translateY(35%) rotate(45deg);
            background-color: #979797FF;
          }

          img {
            border: solid 6px rgba(151,151,151,1);
            cursor: default;
            &:hover {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .manual {
    border-bottom: 1px black dashed;
    cursor: pointer;
  }

  .mainConfigurator * {
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
  }

  .actualization-link{
    text-decoration: none;
  }
</style>
