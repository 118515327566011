<template>
    <div v-bind:class="promoClass" @click="choseTypeOfPromo" @dblclick="$emit('toNextStep')">
        <div v-bind:class="headOfPromoImgClass">
            <p v-bind:class="promoNumberPaddingClass">{{ countForCalling }}</p>
            <p v-bind:class="countForCallingTextClass">результативных звонков!</p>
            <p v-show="this.countForCalling === '1000'" 
               v-bind:class="[countForCallingTextClass, 'antiCrisisProposalText']">Антикризисное предложениe.</p>
        </div>
        <section class="bodyOfPromo">
            <div class="costOfPromoBlock">
                <span class="lineThrough">{{ oldPrice }} руб</span>
                <span class="costOfPromo">{{ newPrice }} руб !</span>
            </div>
            <div class="discountBlock">
              скидка {{ discount }}% на обзвон
            </div>
            <div class="promoProperty">
                <p class="tickSmall">База контактов бесплатно</p>
                <p class="tickSmall">Дозвон до ЛПР</p>
                <p class="tickSmall">Сбор E-mail для отправки КП</p>
                <p class="tickSmall">Полная отчетность (xls)</p>
                <p class="tickSmall">Записи звонков в .mp3</p>
                <p class="tickSmall">Контроль качества звонков</p>
                <p class="tickSmall">Сбор прямых контактов</p>
            </div>
            <div v-bind:class="lprPropertyClass">
                <p v-bind:class="callingOptionClass">Отработка до 5 возражений/вопросов</p>
                <p class="explanationText">
                  <slot></slot>
                </p>
            </div>
            <div class="hotClients" v-if="countForCalling === '1500'">
                <p v-bind:class="callingOptionClass">Мгновенное получение горячих клиентов</p>
            </div>
            <div v-if="chosen" @click="$emit('toNextStep')">
                <span class="chosenPromoBlock">ВЫБРАНО</span>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Promotion',

    props: {
        typeOfPromo: [String, null],
        countForCalling: [String, Number],
        oldPrice: [String, Number],
        newPrice: [String, Number],
        discount: [String, Number],
        chosen: Boolean,
        mode: String,
        reducePromoHeight: Boolean,
    },

    computed: {
      promoClass() {
          return {
              chosenPromo: this.chosen,
              notChosenPromo: !this.chosen,
              promo: true,
              promoDefault1000: this.countForCalling === '1000' && !this.reducePromoHeight &&
                (this.typeOfPromo === '' || this.typeOfPromo === 'promo1000'),
              promo1000: this.countForCalling === '1000'
          };
      },

      headOfPromoImgClass() {
        return {
          modeSmallImg: this.mode === 'small' && this.countForCalling !== '1500',
          modeBigImg: this.mode === 'big',
          modeSmallImgPromo1500: this.countForCalling === '1500',
          headOfPromo: true,
          antiCrisisProposalPromoHead: this.countForCalling === '1000'
        };
      },

      promoNumberPaddingClass() {
        return {
          modeSmallPadding: this.mode === 'small',
          modeBigPadding: this.mode === 'big',
          promoNumber: true,
        };
      },

      countForCallingTextClass() {
        return {
          modeBigCountForCalling: this.mode === 'big',
          resultCalls: true,
        };
      },

      callingOptionClass() {
        return {
          modeSmallOption: this.mode === 'small' && this.countForCalling !== '1500',
          modeBigOption: this.mode === 'big' || this.countForCalling === '1500',
        };
      },

      lprPropertyClass() {
        return {
          lprProperty: this.countForCalling !== '1500',
          lprPropertyPromo1500: this.countForCalling === '1500',
        };
      }
    },

    methods: {
        choseTypeOfPromo() {
            this.$emit('changeTypeOfPromo');
        },
    },
};
</script>

<style scoped>
    .promo {
        display: flex;
        position: relative;
        padding: 0 15px 15px 15px;
        width: 100%;
        height: 482px;
        background: url('_img/promoOthersBackground.jpg') center center no-repeat;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 7px;
        transition: all 0.7s;
        max-width: 250px;
    }

    .promoDefault1000 {
        height: 502px;
        transform: translateY(-4.5%);
        padding-top: 15px;
    }

    .promo1000 {
        background: url('_img/promo1000Background.jpg') center center no-repeat;
    }

    .promo * {
        cursor: pointer;
    }

    .promo:hover {
        box-shadow: 0 0 5px #333;
        height: 524px;
        transform: translateY(-4.5%);
        padding-top: 15px;
    }

    .chosenPromo, .chosenPromo:hover {
        box-shadow: 0 0 0 3px #ffc000;
        height: 524px;
        transform:translateY(-4.5%);
        padding-top: 15px;
    }

    .notChosenPromo {
        opacity: 0.6;
    }

    .promoNumber {
        font-size: 40px;
    }

    .modeSmallPadding {
        padding: 15px 33px 11px 0;
    }

    .modeBigPadding {
        padding: 15px 48px 11px 0;
    }

    .headOfPromo {
        text-align: center;
        padding-bottom: 22px;
        border-bottom: 1px solid black;
    }

    .modeSmallImg {
        background: url('_img/smallFire.png') no-repeat 131px 19px;
    }

    .modeSmallImgPromo1500 {
        background: url('_img/smallFire.png') no-repeat 142px 19px;
    }

    .modeBigImg {
        background: url('_img/bigFire.png') no-repeat 134px 5px;
        background-size: 40px;
    }

    .resultCalls {
        letter-spacing: 1px;
    }

    .bodyOfPromo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 22px;
    }

    .costOfPromoBlock {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        font-size: 15px;
    }

    .costOfPromo {
        padding: 5px;
        font-size: 16px;
        border-radius: 5px;
        background: linear-gradient(180deg,#fbcc05 30%,#e8ab02 80%);
    }

    .lineThrough {
        text-decoration: line-through;
    }

    .promoProperty {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 15px;
    }

    .promoProperty p {
        margin: 3px 0;
        padding-left: 35px;
    }

    .lprProperty {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        margin-bottom: 15px;
    }

    .hotClients {
        display: flex;
        flex-direction: column;
        height: 30px;
    }

    .lprPropertyPromo1500 {
        display: flex;
        flex-direction: column;
        height: 29px;
        align-self: flex-start;
    }

    .lprProperty p, .lprPropertyPromo1500 p {
        padding: 0 0 0 37px;
    }

    .hotClients p {
      margin: 3px 0;
      padding: 0 11px 0 37px;
    }

    .tickSmall, .modeBigOption {
        background: url('_img/tickSmall.png') no-repeat 15px 0;
    }

    .modeBigOption, .chosenPromoBlock, .modeBigCountForCalling, .promoProperty p, .costOfPromoBlock, .promoNumber {
        font-weight: bold;
    }

    .modeSmallOption {
        background: url('_img/notChosenOption.png') no-repeat 13px 0;
    }

    .modeBigCountForCalling {
        color: black;
    }
    
    .antiCrisisProposalText {
      font-style: italic;
      margin-top: 7px;
    }
    
    .antiCrisisProposalPromoHead {
      padding-bottom: 10px;
    }

    .chosenPromoBlock {
      display: inline-block;
      padding: 6px 15px;
      border-radius: 5px;
      margin-top: 10px;
      background: linear-gradient(to bottom, #ffd700 30%, #ffbb00 80%);
      font-size: 16px;
    }

    p.explanationText {
      margin-top: 10px;
    }

    picture {
      height: 27px;
    }

    .discountBlock {
      font-style: italic;
      margin-bottom: 10px;
      color: #707070;
    }

    @media screen and (max-width : 800px) {
      .promo {
        width: 235px;
      }
    }
</style>
