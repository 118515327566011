import { Tree, NodeValue } from 'tree';
import api from "../../../../../components/discountOrder/api/api";

export default {
    namespaced: true,

    state: {
        /**
         * @var {Tree}
         */
        tree: {},

        searchString: '',

        /**
         * @var {Tree}
         */
        searchTree: {},
    },

    getters: {
        getGeoTree: state => {
            return state.tree;
        },
        getGeoSearchTree: state => {
            return state.searchTree;
        },
        isSearchMode: state => {
            return (state.searchString.length >= 3);
        },
        emptySearchResult: state => {
            return state.searchTree.isEmpty();
        },

        // выбранные пункты, отформатированные для отправки запроса на сервер
        selectedGeoUnits: state => {
            const chosenNodesData = state.tree.getChosenNodesDataWithoutRepeat();
            const formattedData = chosenNodesData.map(level => {
                let levelFormatted = {};
                level.forEach(nodeData => {
                    levelFormatted[nodeData.id] = nodeData.name;
                });
                return levelFormatted;
            });

            const country = formattedData[1];
            const regions = formattedData[2];
            const cities = formattedData[3];
            return {...country, ...regions, ...cities};
        }
    },

    mutations: {
        setGeoTree: (state, geoUnits) => {
            state.tree = new Tree(3);

            const countries = geoUnits.filter(geoUnit => geoUnit.parent === 0);
            const countryIdSet = new Set(countries.map(country => country.id));

            const regions = geoUnits.filter(geoUnit => countryIdSet.has(geoUnit.parent));
            const regionIdSet = new Set(regions.map(region => region.id));

            const cities = geoUnits.filter(geoUnit => regionIdSet.has(geoUnit.parent));

            // добавление 1-ого уровня - страна
            countries.forEach(country => {
                let nodeValue = new NodeValue(country, 1, true);
                state.tree.addNodeByParentId(nodeValue, 0);
            });

            // добавление 2-го уровня - область
            regions.forEach(region => {
                let nodeValue = new NodeValue(region, 2);
                state.tree.addNodeByParentId(nodeValue, region.parent);
            });

            // добавление 3-го уровня - город
            cities.forEach(city => {
                let nodeValue = new NodeValue(city, 3);
                state.tree.addNodeByParentId(nodeValue, city.parent);
            });
        },
        expandGeoNode: (state, nodeDesc) => {
            state.tree.expandNode(nodeDesc.level, nodeDesc.id);
        },
        chooseGeoNode: (state, nodeDesc) => {
            state.tree.chooseNode(nodeDesc.level, nodeDesc.id);
        },

        setSearchString(state, searchString) {
            state.searchString = searchString;
        },
        runSearch(state) {
            state.searchTree = state.tree.filterTreeByNodeName(state.searchString);
        },
        resetSearch(state) {
            state.searchString = '';
        },
        expandGeoSearchNode: (state, nodeDesc) => {
            state.searchTree.expandNode(nodeDesc.level, nodeDesc.id);
        },
        syncSearchTree: state => {
            state.searchTree.copyStateOfChoice(state.searchTree.root, state.tree);
        },

        resetTree: state => {
          state.tree.selectOrDropAllNodes(false);
        },
    },

    actions: {
        setGeoTree: async ({commit}) => {
            const geoUnits = await api.getGeoTree();
            commit('setGeoTree', geoUnits);
        },
        chooseGeoSearchNode: ({commit,}, nodeDesc) => {
            commit('chooseGeoNode', nodeDesc);
            commit('syncSearchTree');
        },
        updateSearch: ({commit,getters}, searchString) => {
            commit('setSearchString', searchString);
            if (getters['isSearchMode']) {
                commit('runSearch');
            }
        },
    }
}
