<template>
  <div class="button-wrapper" v-on:scroll="onScroll">
    <Transition name="show-button">
      <button
          class="button"
          @click="_scrollTo('.scrollableDiv', -80)"
          v-if="isVisibleButton"
      >
        Наверх
        <div class="button__arrow">
          <svg width="512px" height="512px" viewBox="-32 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/>
          </svg>
        </div>
      </button>
    </Transition>
  </div>
</template>

<script>
  export default {
    name: 'ButtonUp',
    data() {
      return {
        windowTop: 0,
    };
    },
    computed: {
      isVisibleButton() {
        return this.windowTop > 1000;
      },
    },

    methods: {
      _scrollTo(selector, yOffset = 0) {
        const el = document.querySelector(selector);
        const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
      },

      onScroll(e) {
        this.windowTop = e.target.documentElement.scrollTop;
      },

      handleResize() {
        this.menuHeight = document.querySelector('.button-wrapper').offsetHeight;
      },
    },

    mounted() {
      window.addEventListener("scroll", this.onScroll);
    },

    beforeDestroy() {
      window.removeEventListener("scroll", this.onScroll);
    },
  }
</script>

<style scoped>
  .button-wrapper{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 102%;
  }

  .button{
    position: sticky;
    top: 70%;
    left: 100%;
    background: #424242;
    color: #fff;
    cursor: pointer;
    padding: 8px 20px;
    z-index: 999;
    border: 1px #f7f7f7 solid;
    font-weight: bold;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    transform: rotate(270deg);
    margin-top: 41px;
    margin-bottom: 60px;
    transform-origin: top right;
    transition: .3s;
  }

  .button:hover{
    color: #000;
    border: 1px solid #000;
    background: #fff;
  }
  .button:hover .button__arrow svg{
    fill: #000;
  }

  .button__arrow{
    display: flex;
  }

  .button__arrow svg{
    width: 30px;
    height: 30px;
    fill: #fff;
    transition: .3s;
  }

  .show-button-enter-active, .show-button-leave-active {
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);

  }
  .show-button-enter, .show-button-leave-to{
    opacity: 0;
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  }

  @media (max-width: 1300px) {
    .button-wrapper{
      display: none;
    }
  }
</style>