<template>
    <div v-bind:class="promoClass" @click="choseTypeOfPromo">
        <div class="promo" @dblclick="$emit('toNextStep')">
            <div v-bind:class="headOfPromoClass">
                <div class="priceAndDiscount">
                    <p class="promoNumber">{{ countForCalling }}</p>
                    <div>
                      <p class="discountText">Скидка</p>
                      <p class="discount">{{ discount }}%</p>
                    </div>
                </div>
                <p v-bind:class="countForCallingTextClass">результативных звонков!</p>
                <p v-show="this.countForCalling === '1000'"
                   v-bind:class="[countForCallingTextClass, 'antiCrisisProposalText']">
                    <img src="/images/icons/check-no-circle.svg" height="20px" style="margin: 0 -3px; position: relative; top: 5px"/>
                    Антикризисное предложениe
                </p>
            </div>
            <section class="bodyOfPromo">
                <div class="costOfPromoBlock">
                    <span class="lineThrough">{{ oldPrice }} руб</span>
                    <span class="costOfPromo">{{ newPrice }} &#8381;</span>
                </div>
                <div class="promoProperty">
                    <p class="tickSmall">База контактов бесплатно</p>
                    <p class="tickSmall">Дозвон до ЛПР</p>
                    <p class="tickSmall">Сбор E-mail для отправки КП</p>
                    <p class="tickSmall">Полная отчетность (xls)</p>
                    <p class="tickSmall">Записи звонков в .mp3</p>
                    <p class="tickSmall">Контроль качества звонков</p>
                    <p class="tickSmall">Сбор прямых контактов</p>
                </div>
                <div v-bind:class="lprPropertyClass">
                    <p v-bind:class="callingOptionClass">Отработка до 5 возражений/вопросов</p>
                    <p class="explanationText">
                      <slot></slot>
                    </p>
                </div>
                <div class="hotClients" v-if="countForCalling === '1500'">
                    <p v-bind:class="callingOptionClass">Мгновенное получение горячих клиентов</p>
                </div>
                <div v-if="chosen" @click="$emit('toNextStep')">
                    <span class="call-button-prime">ВЫБРАНО</span>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Promotion',

    props: {
        typeOfPromo: [String, null],
        countForCalling: [String, Number],
        oldPrice: [String, Number],
        newPrice: [String, Number],
        discount: [String, Number],
        chosen: Boolean,
        mode: String,
        reducePromoHeight: Boolean,
    },

    computed: {
      promoClass() {
          return {
              chosenPromoCard: this.chosen,
              promoCard: true,
          };
      },

      headOfPromoClass() {
        return {
          headOfPromo: true,
          antiCrisisProposalPromoHead: this.countForCalling === '1000'
        };
      },

      countForCallingTextClass() {
        return {
          modeBigCountForCalling: this.mode === 'big',
          resultCalls: true,
        };
      },

      callingOptionClass() {
        return {
          modeSmallOption: this.mode === 'small' && this.countForCalling !== '1500',
          modeBigOption: this.mode === 'big' || this.countForCalling === '1500',
        };
      },

      lprPropertyClass() {
        return {
          lprProperty: this.countForCalling !== '1500',
          lprPropertyPromo1500: this.countForCalling === '1500',
        };
      }
    },

    methods: {
        choseTypeOfPromo() {
            this.$emit('changeTypeOfPromo');
        },
    },
};
</script>

<style scoped>
    .promoCard {
      position: relative;
      width: 100%;
      background: url('_img/promoOthersBackground.jpg') center bottom no-repeat;
      box-sizing: content-box;
      border: 1px solid #DDDDDD;
      margin: 2px;
      border-radius: 5px;
      max-width: 260px;
      cursor: pointer;
    }

    .chosenPromoCard, .promoCard:hover {
      border: 3px solid var(--primaryEB, #839F22);
      margin: 0px;
    }

    .promo {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: #ffffff88;
        backdrop-filter: blur(7px);
    }

    .headOfPromo {
      width: calc(100% - 24px);
      top: -3px;
      left: -3px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      padding-bottom: 22px;
      padding: 15px;
      border-radius: 3px;
      color: white;
      background: var(--primaryEB, #839F22);
    }

    .antiCrisisProposalPromoHead {
      background: #F3A537;
    }

    .priceAndDiscount {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .promoNumber {
        font-size: 48px;
        line-height: 48px;
    }

    .discountText, .discount {
      text-align: end;
    }

    .discount {
      font-size: 20px;
      font-weight: bold;
      background: url(/images/icons/price-label.svg) no-repeat 0 0;
      padding-left: 20px;
    }

    .bodyOfPromo {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .costOfPromoBlock {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        width: 100%;
        margin-bottom: 10px;
        font-size: 15px;
    }

    .costOfPromo {
        padding: 5px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 5px;
        border: 1px solid var(--primaryEB, #839F22);
    }

    .lineThrough {
        text-decoration: line-through;
        color: #aaa;
    }

    .promoProperty {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        margin-bottom: 5px;
    }

    .promoProperty p {
        padding-left: 20px;
    }

    .lprProperty {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        margin-bottom: 15px;
    }

    .hotClients {
        display: flex;
        flex-direction: column;
    }

    .lprPropertyPromo1500 {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
    }

    .lprProperty p, .lprPropertyPromo1500 p {
        padding: 0 0 0 20px;
    }

    .hotClients p {
      margin: 3px 0;
      padding: 0 0 0 20px;
    }

    .tickSmall, .modeBigOption {
        background: url('/images/icons/green-check.svg') no-repeat 0px 0;
    }

    .modeSmallOption {
        background: url('/images/icons/gray-cross.svg') no-repeat 0px 0;
    }

    p.explanationText {
        background: url('/images/icons/star.svg') no-repeat 0 -2px,
                    url('/images/icons/star.svg') no-repeat 0 20px;
        margin-top: 10px;
        font-size: 14px;
        line-height: 16px;
        color: #777;
    }

    .discountBlock {
      font-style: italic;
      margin-bottom: 10px;
      color: #707070;
    }
</style>
