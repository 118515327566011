import { state } from './state';
import getters  from './getters';
import mutations  from './mutations';
import actions from './actions';
import geoTree from './modules/geoTree/geoTree';
import sectionTree from './modules/sectionTree/sectionTree';
import options from './modules/options/options';
import createOrder from './modules/createOrder/createOrder';

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions,

    modules: {
        geoTree,
        sectionTree,
        options,
        createOrder,
    },
};
