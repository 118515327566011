export default class TooltipTexts {
    static expandScript() {
        return 'Данная опция подразумевает расширение стандартного дерева скрипта под ваши нужды. Вы сможете' +
            ' добавить поля, сменить алгоритм, расширить вариативность. Это позволит создать уникальный скрипт' +
            ' под ваш продукт и предусмотреть все варианты развития разговора, что в свою очередь повысит' +
            ' общую конверсию по заказу.';
    }

    static onlineReport() {
        return 'Данная опция позволяет видеть обзвон в режиме реального времени.' +
            ' Менеджер позвонил в компанию - и вы тут же видите результат его' +
            ' звонка: информацию о компании, комментарий. Позволяет мгновенно' +
            ' продолжить общение с заинтересовавшимся клиентом.<br/><br/> В стандартном варианте отчет будет ' +
            'приходить каждый день после прозвона заказа и содержать excel файл и полную запись звонков.';
    }

    static baseSelection() {
        return 'Данная опция предоставляет широкий спектр возможностей по индивидуальному подбору баз данных.';
    }

    static addApplicationCRM() {
        return 'Данная опция позволяет заносить информацию о тёплых клиентах в систему CRM. Заказчик предоставляет ' +
            'ссылку на добавление контакта в систему, оператор, после завершения разговора, заполняет необходимые поля ' +
            'с данными, которые уходят в работу. Позволяет мгновенно продолжить разговор с заинтересовавшимся ' +
            'клиентом.<br/><br/> В стандартном варианте данные о тёплых клиентах будут приходить каждый день после прозвона ' +
            'заказа в общем отчёте.';
    }

    static commercialOfferCRM() {
        return 'Данная опция позволяет заносить информацию о клиентах в систему CRM, ' +
            'если во время разговора запросили коммерческое предложение на электронную почту и в мессенджеры.';
    }
}
