<template>
    <div class="call-button-secondary" @click="back">
        <span v-if="withTitle" class="backTitle">Назад</span>
    </div>
</template>

<script>
    export default {
        name: "Back",
        props: {
            withTitle: {
                type: Boolean,
                default: true,
            },
        },

        methods: {
            back() {
                this.$emit('back');
            },
        },
    }
</script>
