export default {
    namespaced: true,
        state: {
            allOptions: false,

            expandScript: false,
            onlineReport: false,
            baseSelection: false,
            commercialOffer: false,
            addApplicationCRM: false,
            addCommercialOfferCRM: false,

            costs: {
                expandScript: 2500,
                onlineReport: 1500,
                baseSelection: 2500,
                commercialOffer: 2000,
                addApplicationCRM: 2500,
                addCommercialOfferCRM: 1000,
            },
    },

    mutations: {
        switchAdditionalOption(state, nameOfOption) {
            state[nameOfOption] = !state[nameOfOption];
        },

        resetAdditionalOptions(state) {
            state.expandScript = false;
            state.onlineReport = false;
            state.baseSelection = false;
            state.commercialOffer = false;
            state.addApplicationCRM = false;
            state.addCommercialOfferCRM = false;
            state.allOptions = false;
        },

        setAdditionalOption(state, [nameOfOption, stateOfOption]) {
            state[nameOfOption] = stateOfOption;
        },

        setAllOptions(state, value) {
            state.allOptions = value;
        },
    },

    actions: {
        // Сделано из за особенностей включения доп.опций.
        switchAdditionalOption(context, nameOfOption) {
            context.commit('switchAdditionalOption', nameOfOption);


            if (context.rootGetters['advancedPromo/getManualDatabase']) {
                context.commit('setAdditionalOption', ['baseSelection', true]);

                if (nameOfOption === 'baseSelection') {
                    window.echo('Ручной подбор базы был выбран на предыдущем шаге');
                }
            }


            if (context.rootGetters['advancedPromo/getOurDatabase']) {
                if (!context.rootGetters['advancedPromo/allowSwitchBaseSelection']
                    && nameOfOption === 'baseSelection'
                ) {
                    context.commit('setAdditionalOption', ['baseSelection', false]);

                    if (nameOfOption === 'baseSelection') {
                        window.echo('Ручной подбор базы недоступен при выборе "Онлайн-отчетность"');
                    }
                }

                if (!context.rootGetters['advancedPromo/allowSwitchOnlineReport'] && nameOfOption === 'onlineReport') {
                    context.commit('setAdditionalOption', ['onlineReport', false]);

                    if (nameOfOption === 'onlineReport') {
                        window.echo('Онлайн отчетность недоступна при выборе опции "Ручной подбор базы данных"');
                    }
                }
            }
        },
    },
    };
