<template>
    <div class="forwardButtonWrapper">
        <div :class="buttonClass" @click="forward">
            {{ title }}
            <img src="/images/icons/arrow-right.svg"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "Forward",
    props: {
        withTitle: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            require: false,
            default: 'Далее',
        },
        size: {
            type: String,
            require: false,
            default: '',
        },
        disabled: {
            type: String,
            require: false,
            default: '',
        },
    },

    computed: {
        buttonClass() {
            return `call-button-prime ${this.size} ${this.disabled}`;
        },
    },

    methods: {
        forward() {
            this.$emit('forward');
        },
    },
}
</script>

<style scoped>
.forwardButtonWrapper {
    display: flex;
    justify-content: center;
}
</style>
