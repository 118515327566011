export default {
    namespaced: true,
    state: {
        introductionVisible: true,

        discountSections: {
            allowTransition: false,
            citiesAndRubricsVisible: false,
            optionsVisible: false,
            createOrderVisible: false,
            successVisible: false,
        },

        advancedSections: {
            allowTransition: false,
            citiesAndRubricsVisible: false,
            optionsVisible: false,
            createOrderVisible: false,
            successVisible: false,
        }
    },
    mutations: {
        hideDiscountSection(state, section) {
            state.discountSections[section + 'Visible'] = false;
        },

        openDiscountSection(state, section) {
            state.discountSections[section + 'Visible'] = true;
        },

        openAdvancedSection(state, section) {
            state.advancedSections[section + 'Visible'] = true;
        },

        hideAdvancedSection(state, section) {
            state.advancedSections[section + 'Visible'] = false;
        },

        setIntroductionSection(state, value) {
            state.introductionVisible = value;
        },
    },

    actions: {
        openSection({rootState, commit}, openingModal) {
            // Introduction является общей секцией поэтому меняется отдельным методом
            if (openingModal === 'introduction') {
                return commit('setIntroductionSection', true);
            }
            // Переходим на секцию Advanced, если выбран пакет из advanced
            if (rootState.advancedPromo.typeOfPackage) {
                return commit('openAdvancedSection', openingModal);
            }
            // Переход на секцию Discount, если выбран пакет из discount
            if (!rootState.advancedPromo.typeOfPackage) {
                return commit('openDiscountSection', openingModal);
            }
        },

        hideSection({rootState, commit}, closingModal) {
            if (closingModal === 'introduction') {
                return commit('setIntroductionSection', false);
            }
            if (rootState.advancedPromo.typeOfPackage) {
                return commit('hideAdvancedSection', closingModal);
            }
            if (!rootState.advancedPromo.typeOfPackage) {
                return commit('hideDiscountSection', closingModal);
            }
        },
    }
}