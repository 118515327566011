import { Tree, NodeValue } from 'tree';
import api from "../../../../../components/discountOrder/api/api";

export default {
    namespaced: true,

    state: {
        // используется для форматирования выбранных секций в запросе на расчет заказа
        sectionNameById: {},

        /**
         * @type {Tree}
         */
        tree: {},

        searchString: '',

        /**
         * @type {Tree}
         */
        searchTree: {},
    },

    getters: {
        getSectionTree: state => {
            return state.tree;
        },
        getSectionSearchTree: state => {
            return state.searchTree;
        },
        isSearchMode: state => {
            return (state.searchString.length >= 3);
        },
        emptySearchResult: state => {
            return state.searchTree.isEmpty();
        },
        selectOrDropAllText: state => {
            return state.tree.fullySelected() ? 'Удалить все' : 'Выбрать все';
        },

        // выбранные пункты, отформатированные для отправки запроса на сервер
        selectedSections: state => {
            const chosenSubsection = state.tree.getChosenNodesDataFromLevel(2);
            let formattedSections = {};
            chosenSubsection.forEach(subsection => {
                const {id, name, parent} = subsection;
                if (!(parent in formattedSections)) {
                    formattedSections[parent] = {name: state.sectionNameById[parent]};
                }
                formattedSections[parent][id] = name;
            });
            return formattedSections;
        },
    },

    mutations: {
        setSectionTree(state, sectionsAndSubsections) {
            state.tree = new Tree(2);
            const sections = sectionsAndSubsections.filter(item => item.parent === 0);
            const sectionIdSet = new Set(sections.map(section => section.id));
            const subsections = sectionsAndSubsections.filter(item => sectionIdSet.has(item.parent));

            sections.forEach(({id, name}) => state.sectionNameById[id] = name);

            // 1 уровень - рубрики
            sections.forEach(section => {
                let nodeValue = new NodeValue(section, 1);
                state.tree.addNodeByParentId(nodeValue, 0);
            });

            // 2 уровень - подрубрики
            subsections.forEach(subsection => {
                let nodeValue = new NodeValue(subsection, 2);
                state.tree.addNodeByParentId(nodeValue, subsection.parent);
            });
        },
        expandSectionNode: (state, node) => {
            state.tree.expandNode(node.level, node.id);
        },
        chooseSectionNode: (state, nodeDesc) => {
            state.tree.chooseNode(nodeDesc.level, nodeDesc.id);
        },
        selectOrDropAll: state => {
            state.tree.selectOrDropAllNodesByDefaultStrategy();
        },

        setSearchString(state, searchString) {
            state.searchString = searchString;
        },
        runSearch(state) {
            state.searchTree = state.tree.filterTreeByNodeName(state.searchString);
        },
        resetSearch(state) {
            state.searchString = '';
        },
        expandSectionSearchNode: (state, nodeDesc) => {
            state.searchTree.expandNode(nodeDesc.level, nodeDesc.id);
        },
        syncSearchTree: state => {
            state.searchTree.copyStateOfChoice(state.searchTree.root, state.tree);
        },

        resetTree: state => {
            state.tree.selectOrDropAllNodes(false);
        },
    },

    actions: {
        setSectionTree: async ({commit}) => {
            const sectionsAndSubsections = await api.getSectionTree();
            commit('setSectionTree', sectionsAndSubsections);
        },
        chooseSectionSearchNode: ({commit,}, nodeDesc) => {
            commit('chooseSectionNode', nodeDesc);
            commit('syncSearchTree');
        },
        selectOrDropAll({commit,getters}) {
            commit('selectOrDropAll');
            if (getters.getSectionSearchTree.root) {
                commit('syncSearchTree');
            }
        },
        updateSearch({commit,getters,}, searchString) {
            commit('setSearchString', searchString);
            if (getters['isSearchMode']) {
                commit('runSearch');
            }
        },
    },
}
