<template>
    <div>
        <SearchBar class="searchBar"
            width="80%"
            placeholder="Поиск города/региона"
            @input="updateSearch"
            @reset="resetSearch"
        />

        <div v-if="isSearchMode">
            <span v-if="emptySearchResult"
                  class="emptySearch">Ничего не найдено</span>
            <TreeComponent v-else
               :node="getGeoSearchTree.root"
               :innerCheckboxMode="['dark']"
               @expand-node="expandGeoSearchNode"
               @choose-node="chooseSearchNode"
            />
        </div>

        <TreeComponent v-else
            :node="getGeoTree.root"
            :with-important-nodes="true"
            :innerCheckboxMode="['dark']"
            @expand-node="expandGeoNode"
            @choose-node="chooseNode"
        />
    </div>
</template>

<script>
    import {mapGetters, mapMutations, mapActions,} from 'vuex'
    import {TreeComponent,} from 'tree'
    import SearchBar from 'searchbar';
    export default {
        name: "GeoTree",
        components: {
            TreeComponent,
            SearchBar,
        },

        computed: {
            ...mapGetters('advancedPromo/geoTree', [
                'getGeoTree',
                'getGeoSearchTree',
                'isSearchMode',
                'emptySearchResult',
            ]),
        },

        methods: {
            ...mapActions('advancedPromo', [
                'calculateOrder',
            ]),
            ...mapMutations('advancedPromo/geoTree', [
                'expandGeoNode',
                'chooseGeoNode',
                'expandGeoSearchNode',
                'resetSearch',
            ]),
            ...mapActions('advancedPromo/geoTree', [
                'chooseGeoSearchNode',
                'updateSearch',
            ]),
            chooseNode(nodeDesc) {
                this.chooseGeoNode(nodeDesc);
                this.calculateOrder();
            },
            chooseSearchNode(nodeDesc) {
                this.chooseGeoSearchNode(nodeDesc);
                this.calculateOrder();
            },
        },
    }
</script>

<style scoped src="../_css/SearchBlock.css"></style>
