import api from '../../../components/advancedPromo/api/api';

const actions = {
    createOrder(context) {
        return api.createOrder(context.getters['sectionTree/selectedSections'],
            context.getters['geoTree/selectedGeoUnits'], context.getters.filtersData, context.getters.userInputData,
            context.state.createOrder.userName, context.state.dataBaseType === 'exportBase' ?
                context.getters.foundCompaniesCount : context.state.clientDbSize,
            context.getters.companiesCount, context.getters.typeOfPackage, context.getters.totalCost);
    },

    async calculateOrder({getters, commit,}) {
        if (getters['calculationInProgress']) {
            commit('setOrderSettingsUpdated', true);
        } else {
            commit('setCalculationInProgress', true);

            commit('setOrderSettingsUpdated', true);
            while (getters['orderSettingsUpdated']) {
                commit('setOrderSettingsUpdated', false);
                const orderSettings = {
                    sections_data: JSON.stringify(getters['sectionTree/selectedSections']),
                    cities_data: JSON.stringify(getters['geoTree/selectedGeoUnits']),
                    ajax: true,
                    cache: 0,
                    filters_data: JSON.stringify(getters['filtersDataOrderCalculation']),
                    last_configuration_data: JSON.stringify(getters['lastConfigurationData']),
                    type_of_package: getters['typeOfPackage']
                };
                const foundCompaniesCount = await api.calculateCompaniesCount(orderSettings);
                commit('setFoundCompaniesCount', foundCompaniesCount);

                if (
                    getters.companiesCount > foundCompaniesCount &&
                    foundCompaniesCount >= getters.minCountOfCalls
                ) {
                    commit('setCompaniesCount', foundCompaniesCount);
                }
            }

            commit('setCalculationInProgress', false);
        }
    },
};

export default actions;
