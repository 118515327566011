import Vue from 'vue';
import MainConfigurator from './MainConfigurator';
import Vuex from 'vuex';
import VModal from 'vue-js-modal';
import store from './store/store';

Vue.use(Vuex);
Vue.use(VModal, { dialog: true });

window.vue_main_configurator = new Vue({
  el: '#vue_main_configurator',
  store,
  render: h => h(MainConfigurator),
});
