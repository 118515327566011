<template>
    <section class="promotionsBlock">
        <Flicking :options="options" class="flicking" ref="slider">
            <div class="promotion-slide">
              <Promotion :typeOfPromo="typeOfPromo" @mouseover.native="reducePromoHeight"
                                                    @mouseout.native="restorePromoHeight"
                         countForCalling="500"
                         oldPrice="30 500"
                         newPrice="27 500"
                         discount="10"
                         mode="small"
                         class="panel"
                         :chosen="typeOfPromo === 'promo500'"
                         @changeTypeOfPromo="choseTypeOfPromo('promo500')"
                         @toNextStep="$emit('toNextStep')">
              </Promotion>
              <Promotion :typeOfPromo="typeOfPromo"
                         countForCalling="1000"
                         oldPrice="56 700"
                         newPrice="42 500"
                         discount="25"
                         mode="big"
                         class="panel"
                         :chosen="typeOfPromo === 'promo1000'"
                         :reducePromoHeight="reducePromo"
                         @changeTypeOfPromo="choseTypeOfPromo('promo1000')"
                         @toNextStep="$emit('toNextStep')">
                Высокая конверсия! <br><br>
                Оптимальный выбор для проведения любого обзвона.
              </Promotion>
              <Promotion :typeOfPromo="typeOfPromo" @mouseover.native="reducePromoHeight"
                                                    @mouseout.native="restorePromoHeight"
                         countForCalling="1500"
                         oldPrice="82 900"
                         newPrice="58 000"
                         discount="30"
                         mode="small"
                         class="panel"
                         :chosen="typeOfPromo === 'promo1500'"
                         @changeTypeOfPromo="choseTypeOfPromo('promo1500')"
                         @toNextStep="$emit('toNextStep')">
              </Promotion>
            </div>
            <div class="promotion-slide">
              <PromotionPRO :typeOfPackage="typeOfPackage" @mouseover.native="reducePromoHeight"
                     @mouseout.native="restorePromoHeight"
                     oneCallCost="35"
                     price="17 500"
                     mode="small"
                    class="panel"
                     :chosen="typeOfPackage === 'low'"
                     @changeTypeOfPackage="choseTypeOfPackage('low')"
                     @toNextStep="$emit('toNextStep')">
              </PromotionPRO>
              <PromotionPRO :typeOfPackage="typeOfPackage"
                     oneCallCost="52"
                     price="26 000"
                     mode="big"
                    class="panel"
                     :chosen="typeOfPackage === 'medium'"
                     :reducePromoHeight="reducePromo"
                     @changeTypeOfPackage="choseTypeOfPackage('medium')"
                     @toNextStep="$emit('toNextStep')">
              </PromotionPRO>
              <PromotionPRO :typeOfPackage="typeOfPackage" @mouseover.native="reducePromoHeight"
                         @mouseout.native="restorePromoHeight"
                         oneCallCost="58"
                         price="29 000"
                         mode="small"
                            class="panel"
                         :chosen="typeOfPackage === 'large'"
                         @changeTypeOfPackage="choseTypeOfPackage('large')"
                         @toNextStep="$emit('toNextStep')">
              </PromotionPRO>
            </div>
        </Flicking>
        <ArrowPrev @click="prevSlide" class="slider-arrow slider-arrow_prev" />
        <ArrowNext @click="nextSlide" class="slider-arrow slider-arrow_next" />
    </section>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import Promotion from '../Promotion/Promotion';
import PromotionPRO from '../../../../advancedPromo/components/Modals/Promotion/Promotion';
import ArrowNext from "../../../../Arrows/ArrowNext.vue";
import ArrowPrev from "../../../../Arrows/ArrowPrev.vue";
import { Flicking } from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";

export default {
    name: 'Promotions',

    data() {
        return {
            reducePromo: false,
            options: {
                circular: true,
                inputType: [],
            },
        };
    },

    components: {
        Promotion,
        PromotionPRO,
        Flicking,
        ArrowNext,
        ArrowPrev,
    },

    methods: {
        ...mapMutations('simplePromo', [
            'setTypeOfPromo',
        ]),
        ...mapMutations('advancedPromo', [
            'setTypeOfPackage',
        ]),
        choseTypeOfPromo(typeOfPromo) {
            this.setTypeOfPromo(typeOfPromo);
            this.setTypeOfPackage(null); // Сбрасываем пакеты ПРО, потому что может быть выбран только один пакет
        },

        choseTypeOfPackage(typeOfPackage) {
            this.setTypeOfPackage(typeOfPackage);
            this.setTypeOfPromo(null);
        },

        reducePromoHeight() {
            this.reducePromo = true;
        },

        restorePromoHeight() {
            this.reducePromo = false;
        },

        nextSlide() {
            this.$refs.slider.next();
        },

        prevSlide() {
            this.$refs.slider.prev();
        },
    },

    computed: {
        ...mapGetters('simplePromo',[
          'typeOfPromo',
        ]),
        ...mapGetters('advancedPromo', [
            'typeOfPackage',
        ])
    },
};
</script>

<style scoped src="../_css/slide.css"></style>
<style scoped>
    .promotionsBlock {
        display: flex;
        justify-content: space-evenly;
        height: 550px;
        position: relative;
        z-index: 50;
    }

    .promotion-slide{
        display: flex;
        gap: 15px;
        margin: 0 20px;
        padding: 40px 0;
    }

    .slider-arrow{
        position: absolute;
        z-index: 100;
        cursor: pointer;
    }

    .slider-arrow_next{
        top: 50%;
        right: 0;
    }

    .slider-arrow_prev{
        top: 50%;
        left: 0;
    }
</style>
