<template>
    <main v-show="visible">
        <v-dialog @closed="beforeCloseModal"/>
        <div class="paymentBlock">
            <div class="heading">
                <h1>Ваш заказ:</h1>
                <Back class="back" @back="changeModal('options', 'createOrder')"/>
            </div>
            <section class="billInfo">
                <div class="billInfoLeftBlock">
                    <div class="billInfoParams">
                        <div class="billInfoParam">
                            <span>Обзвоним:</span>
                            <span v-if="baseSelection">{{ companiesCount }} фирм</span>
                            <span v-else-if="dataBaseType === 'exportBase'">
                                {{ companiesCount }} фирм из {{ foundCompaniesCount }}
                            </span>
                            <span v-else>{{ companiesCount }} фирм из {{ clientDbSize }}</span>
                        </div>
                        <div class="billInfoParam">
                            <span>Стоимость:</span>
                            <span>{{ totalCost.toLocaleString('ru') }} руб</span>
                        </div>
                        <div class="billInfoParam">
                            <span>Стоимость звонка:</span>
                            <span>{{ oneCallCost }} руб</span>
                        </div>
                        <div class="billInfoParam"><span class="gap"></span></div>
                        <div class="billInfoParam">
                            <span>Отчеты:</span>
                            <span v-if="onlineReport" class="options chosenOption">Онлайн</span>
                            <span v-else class="options chosenOption">С записью звонков</span>
                        </div>
                        <div class="billInfoParam">
                            <span>Контроль качества:</span>
                            <span class="options chosenOption">Проверка звонков</span>
                        </div>
                        <div class="billInfoParam">
                            <span>Скрипт разговора:</span>
                            <span v-if="expandScript" class="options chosenOption">Расширенный</span>
                            <span v-else class="options chosenOption">Составляем мы</span>
                        </div>
                        <div class="billInfoParam">
                            <span>База данных:</span>
                            <span v-if="baseSelection" class="options chosenOption">На заказ</span>
                            <span v-else-if="dataBaseType === 'client'" class="options chosenOption">Клиента</span>
                            <span v-else class="options chosenOption">ExportBase</span>
                        </div>
                        <div class="billInfoParam">
                            <span>Получение лида в CRM</span>
                            <span v-if="addApplicationCRM" class="options chosenOption">Заказано</span>
                            <span v-else class="options notChosenOption">Не заказано</span>
                        </div>
                    </div>
                </div>
                <div class="billInfoRightBlock">
                    <div class="billInfoParams">
                        <div class="billInfoParam">
                            <span>Обзвон:</span>
                            <span v-if="commercialOffer" class="options chosenOption">Отправка КП</span>
                            <span v-else class="options chosenOption">Без отправки КП</span>
                        </div>
                        <div class="billInfoParam">
                            <span>Дозвон до ЛПР:</span>
                            <span v-if="callContactPerson" class="options chosenOption">Связь с директором</span>
                            <span v-else class="options notChosenOption">На общих условиях</span>
                        </div>
                        <div class="billInfoParam">
                            <span>Сбор контактов ЛПР:</span>
                            <span v-if="decisionMakerContacts" class="options chosenOption">Уточняем прямые контакты</span>
                            <span v-else class="options notChosenOption">На общих условиях</span>
                        </div>
                        <div class="billInfoParam">
                            <span>Возражения:</span>
                            <span v-if="workWithObjections === 1" class="options chosenOption">До 5</span>
                            <span v-else-if="workWithObjections === 2" class="options chosenOption">До 10</span>
                            <span v-else class="options notChosenOption">Не заказано</span>
                        </div>
                        <div class="billInfoParam">
                            <span>Работа с согласиями:</span>
                            <span v-if="workWithAgreements" class="options chosenOption">Получение горячих клиентов</span>
                            <span v-else class="options notChosenOption">Не заказано</span>
                        </div>
                        <div class="billInfoParam">
                            <span>Доп.прозвон:</span>
                            <span v-if="recallFirms" class="options chosenOption">Заказан</span>
                            <span v-else class="options notChosenOption">Не заказано</span>
                        </div>
                    </div>
                    <form method="post" target="_blank" action="/individual_commercial_offer" ref="form">
                        <input type="text" name="type" :value="typeOfPackageAdapter" hidden/>
                        <input type="text" name="additionalFilters" :value="additionalFilters" hidden/>
                        <input type="number" name="cost" :value="totalCost" hidden/>
                        <input type="number" name="callsCount" :value="companiesCount" hidden/>
                        <CommercialOfferButton @click.native="getCommercialOffer" text="Скачать коммерческое" />
                    </form>
                </div>
            </section>
            <section class="userInfo">
                <div class="contactsAndComment">
                    <div class="userInfoBlock">
                        <h2>Контакты для обратной связи</h2>
                        <p class="phone">
                            <span>Ваш телефон:</span>
                            <InputValidation :isValid="isValidPhone" :ignoreTouchedProps="ignoreTouched"
                                             insideText="+7 902 330-56-65" :value="userPhone"
                                             @input="inputPhone" mask="+{7} (000) 000-00-00"/>
                        </p>
                        <p class="email">
                            <span>Ваш e-mail:</span>
                            <InputValidation :isValid="isValidEmail" :ignoreTouchedProps="ignoreTouched"
                                             insideText="Введите эл.почту" :value="userEmail" @input="inputEmail"/>
                        </p>
                        <p class="name" style="display: none">
                            <span>Ваше имя:</span>
                            <InputValidation :isValid="isValidName" :ignoreTouchedProps="ignoreTouched"
                                             insideText="Иван" @input="inputName" :value="userName"/>
                        </p>
                        <p class="date" title="По московскому времени">
                            <span class="whenCall">Когда вам позвонить:</span>
                            <span class="select">
                                <select v-model="selectedDateIndex" @change="inputTimeAndDateRecall" style="width:148px">
                                    <option v-for="(option, index) in filteredDate" :value="index" v-bind:key="index">
                                        {{ option.content }}
                                    </option>
                                </select>
                                <select v-model="selectedTimeIndex" @change="inputTimeAndDateRecall" style="width:100px">
                                    <option v-for="(option, index) in filteredTime" :value="index" v-bind:key="index">
                                        {{ option.content }}
                                    </option>
                                </select>
                            </span>
                        </p>
                    </div>
                    <div class="userComment">
                        <h2>Ваши комментарии:</h2>
                        <textarea class="cin_user_comment" alt="Минимум 3 символа" @input="inputComment" :value="userComment"
                                  placeholder="Уточните цель обзвона">
                        </textarea>
                    </div>
                </div>
                <div class="paymentTypeAndAgreementBlock">
                    <div class="paymentTypeBlock">
                        <h2>Способ оплаты</h2>
                        <div class="paymentTypeInputs">
                            <p class="radioBlock cashlessBlock">
                                <input type="radio" id="cashlessTarif" name="paymentRadioButton" :value=1
                                       :checked="userTypePay === 1"
                                       @change="inputTypePay">
                                <label for="cashlessTarif">
                                    <span v-bind:class="{ headOfCheckedRadio: userTypePay === 1 }">Безналичный расчет</span>
                                    <span>Выставить счет без НДС</span>
                                    <!--Старый код, взяли для экономии времени.-->
                                    <span class="call-green-link add_text uploadRequisites _mod-inlock
                                            _requisiten_input_main_trigger">Прикрепить реквизиты</span>
                                    <span class="file-loader" data-purpose="requisiten">
                                        <input class="_mod-hide _input_upload_file _requisiten_input_main" type="file"
                                               name="requisiten">
                                    </span>
                                    <!--Старый код, взяли для экономии времени.-->
                                </label>
                            </p>
                            <p class="radioBlock eMoney">
                                <input type="radio" id="electronicMoneyTarif" name="paymentRadioButton" :value=0
                                       :checked="userTypePay === 0"
                                       @change="inputTypePay">
                                <label for="electronicMoneyTarif">
                                    <span v-bind:class="{ headOfCheckedRadio: userTypePay === 0 }">Банковские карты</span>
                                    <span>Я.деньги, webMoney, Qiwi и др.</span>
                                </label>
                            </p>
                        </div>
                    </div>
                    <div class="agreementInfo">
                        <div class="personalDataAgreement">
                            <input type="checkbox" v-model="privacyPolicyChecked"
                                   :class="[privacyPolicyChecked ? '' : 'invalid']">
                            <p>Даю согласие на обработку моего e-mail и телефона в соответствии с
                                <a class="call-green-link" href="https://export-base.ru/privacy_policy" rel="noopener noreferrer"
                                   target="_blank">политикой конфиденциальности ExportBase</a>
                            </p>
                        </div>
                        <p class="text">
                            После отправки запроса мы свяжемся с вами, уточним детали, предложим скрипт разговора и
                            начнем обзвон. По окончании предоставим отчет и необходимые закрывающие документы.
                        </p>
                    </div>
                </div>
                <div class="buttonPayWrapper">
                    <div class="call-button-prime xxl" @click="sendRequest">Заказать обзвон</div>
                    <img src="./_img/preloader.gif" alt="" class="preloader" v-show="orderCreation"/>
                </div>
            </section>
        </div>
    </main>
</template>

<script>
import InputValidation from 'input-validation';
import Back from '../../Navigation/Back/Back';
import CommercialOfferButton from './../../../../commercialOffers/Button/CommercialOfferButton';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';

export default {
    name: 'CreateOrder',

    components: {
      InputValidation,
      Back,
      CommercialOfferButton,
    },

    props: {
      visible: Boolean,
    },

    methods: {
        changeModal(openingModal, closingModal) {
            this.$emit('changeModal', openingModal, closingModal);
        },

        async sendRequest() {
            this.ignoreTouched = true;

            if (this.allowCreateOrder) {
                this.orderCreation = true;
                await this.createOrder();
                this.orderCreation = false;

                window.document.querySelector('[data-tab_button="main"]').style.zIndex = 0;
                this.$modal.show('dialog', {
                    text: `<p>Заявка для заказа по акции отправлена.<br>
                              С вами свяжется менеджер и обсудит детали заказа.</p><br>
                           <p style="opacity: 0.6">Если вы хотите скачать базу без проведения обзвона,<br> то можете сделать это на сайте
                              <a href="http://export-base.ru/" target="_blank" style="color: #1B88C1">
                                export-base.ru
                              </a>
                           </p>`,
                    buttons: [
                        {
                            title: 'OK',
                            handler: () => {
                                this.beforeCloseModal();
                            },
                        },
                    ],
                });
            }
        },

        inputEmail(email) {
            this.setUserEmail(email);
            this.isValidEmail = this.emailPattern;
        },

        inputPhone(phone) {
            this.setUserPhone(phone);
            this.isValidPhone = this.phonePattern;
        },

        inputName(name) {
            this.setUserName(name);
            this.isValidName = this.namePattern;
        },

        inputTypePay(event) {
            this.setUserTypePay(Number(event.target.value));
        },

        inputComment(event) {
          this.setUserComment(event.target.value);
        },

        inputTimeAndDateRecall() {
            this.setTimeAndDateRecall();
        },

        setTimeAndDateRecall() {
            this.setTimeRecall(this.timeRecall);
            this.setTimeRecallData(this.timeRecallData);
        },

        resetState() {
          this.resetTypeOfPackage();
          this.resetUserdata();
          this.resetGeoTree();
          this.resetSectionTree();
          this.resetAdditionalOptions();
          this.ignoreTouched = false;
        },

        beforeCloseModal() {
          if (this.ignoreTouched) {
            this.$modal.hide('dialog');
            window.document.querySelector('[data-tab_button="main"]').style.zIndex = '';
            this.resetState();
            this.changeModal('introduction', 'createOrder');
            window.MFunctions.showHeadConfigurator();
          }
        },

        getCommercialOffer() {
          this.$refs.form.submit();
        },

        ...mapActions('advancedPromo/createOrder', [
          'getDateAndTimeSelector'
          ]),

        ...mapActions('advancedPromo', [
              'createOrder',
          ]),

        ...mapMutations('advancedPromo/createOrder',
          [
          'setUserEmail',
          'setUserComment',
          'setUserName',
          'setUserPhone',
          'setUserTypePay',
          'setTimeRecall',
          'setTimeRecallData',
          'setTimezoneOffset'
          ],
        ),

      ...mapMutations('advancedPromo/options', [
        'resetAdditionalOptions'
      ]),

        ...mapMutations(
          {
              resetTypeOfPackage : 'advancedPromo/resetState',
              resetUserdata: 'advancedPromo/createOrder/resetState',
              resetGeoTree: 'advancedPromo/geoTree/resetTree',
              resetSectionTree: 'advancedPromo/sectionTree/resetTree',
          }
        ),
    },

    computed: {
        emailPattern() {
            const regExpEmail = /.+@.+\..+/i;
            return regExpEmail.test(this.userEmail);
        },

        phonePattern() {
            const regExpPhone = /^[+]?[\d() -]{16}\d$/;
            return regExpPhone.test(this.userPhone);
        },

        namePattern() {
            const regExpName = /^[a-zA-Zа-яёА-ЯЁ]+$/u;
            return regExpName.test(this.userName);
        },

        ...mapState('advancedPromo/createOrder',
          [
            'timeSelectors',
            'dateSelectors',
            'userName',
            'userTypePay',
            'userComment',
            'timeRecallData',
            'timeRecall',
            'userEmail',
            'userPhone',
            'timezoneOffset'
          ]
        ),

        ...mapState('advancedPromo/options',
          [
              'expandScript',
              'onlineReport',
              'baseSelection',
              'commercialOffer',
              'addApplicationCRM',
          ]
        ),

        ...mapState('advancedPromo', [
          'typeOfPackage',
          'dataBaseType',
          'clientDbSize',
        ]),

        ...mapGetters('advancedPromo', [
          'totalCost',
          'foundCompaniesCount',
          'companiesCount',
          'costOfPromo',
          'oneCallCost',
          'callContactPerson',
          'decisionMakerContacts',
          'workWithObjections',
          'recallFirms',
          'workWithAgreements',
          'additionalFilters',
          'typeOfPackageAdapter'
          ]),

        filteredDate() {
            return this.dateSelectors.filter(i => !i.holiday);
        },

        filteredTime() {
            if (this.dateSelectors.length === 0) {
                return [];
            }

            return this.dateSelectors[this.selectedDateIndex].today ?
              this.timeSelectors.filter(i => !i.past) : this.timeSelectors;
        },

        timeRecall() {
            return `${this.filteredDate[this.selectedDateIndex].content}`+
              ` ${'в ' + this.timeInMoscowTimezone}`;
        },

        timeRecallData() {
            return `${this.filteredDate[this.selectedDateIndex].data}`+
              ` ${this.timeInMoscowTimezone}`;
        },

        timeInMoscowTimezone() {
            const localTimeArr = this.filteredTime[this.selectedTimeIndex].data.split(':');

            const localHour = localTimeArr[0];
            const hourInMoscow = localHour - this.timezoneOffset;

            return hourInMoscow + ':' + localTimeArr[1];
        },

      allowCreateOrder() {
        return this.isValidEmail && this.isValidPhone && this.privacyPolicyChecked &&
          !this.orderCreation;
      },
    },

    data() {
        return {
            isValidEmail: false,
            isValidPhone: false,
            isValidName: false,
            selectedDateIndex: 0,
            selectedTimeIndex: 0,
            privacyPolicyChecked: true,
            ignoreTouched: false,
            commercialProposal: '',
            orderCreation: false
        };
    },

    async beforeMount() {
        this.setTimezoneOffset();
        await this.getDateAndTimeSelector();
        this.setTimeAndDateRecall();
    },
};
</script>

<style scoped src="../_css/modals.css"></style>
<style scoped lang="less">

.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .paymentBlock {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .paymentTypeBlock {
        width: 200px;
    }

    .buttonPayWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .hiddenButton {
      opacity: 0.3;
      cursor: default;

      &:hover {
        box-shadow: 0 0 1px #333;
      }
    }

    .billInfo {
        display: flex;
        justify-content:space-between;
        padding: 15px;
        border: 1px solid var(--primaryEB, #839F22);
        border-radius: 5px;
        font-size: 16px;
    }

    .billInfoRightBlock {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
    }

    .billInfoParams {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 300px;
    }

    .billInfoParam {
        display: flex;
        justify-content:space-between;

        span:last-child {
            font-weight: bold;
        }

        span:first-child {
            white-space: nowrap;
        }
    }

    .contactsAndComment {
        display: flex;
        justify-content: space-between;
        gap: 15px;
    }

    .paymentTypeAndAgreementBlock {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .agreementInfo {
        width: 400px;
    }


    .gap {
        height: 20px;
    }

    h1 {
        font-size: 24px;
        align-self: center;
        font-weight: bold;
    }

    h2 {
        font-size: 20px;
        align-self: center;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .userInfo {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .paymentTypeInputs span:first-child {
        font-size: 16px;
    }

    .billInfoColumnLeft span, span.headOfCheckedRadio, .userInfoBlock span, .headOfPaymentTypeBlock,
    .commercialProposal span {
        font-weight: bold;
        opacity: 1;
    }

    .billInfoColumnLeft span, .billInfoColumnRight span {
        margin: 2px 0;
    }

    .oneCallCost {
        color: #999;
    }

    textarea {
        resize: vertical;
        height: 120px;
        width: 350px;
        border-radius: 5px;
        padding: 10px;
    }

    input[type=radio] {
        align-self: flex-start;

        &:before {
            border: 1px solid #ccc;
            background: #eee;
            top: -1px;
            left: -1px;
            width: 15px;
            height: 15px;
            border-radius: 15px;
            position: relative;
            content: '';
            display: inline-block;
        }

        &:checked:after {
            border: 1px solid #eee;
            background: #839F22;
            top: -20px;
            width: 13px;
            height: 13px;
            border-radius: 15px;
            position: relative;
            content: '';
            display: inline-block;
        }
    }

    input[type=checkbox] {
        align-self: flex-start;

        &:before {
            border: 1px solid #ccc;
            background: #eee;
            top: -1px;
            left: -1px;
            width: 15px;
            height: 15px;
            border-radius: 3px;
            position: relative;
            content: '';
            display: inline-block;
        }

        &:checked:after {
            border: 1px solid #eee;
            background-color: #839F22;
            background-image: url(/images/icons/white-check.svg);
            background-repeat: no-repeat;
            background-position: 35% 50%;
            top: -20px;
            width: 13px;
            height: 13px;
            border-radius: 3px;
            position: relative;
            content: '';
            display: inline-block;
        }
    }

    .phone, .email, .name, .date {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .date {
        justify-content: flex-start;
        align-items: center;
        margin-top: 2.5%;
    }

    .whenCall {
        width: 25%;
    }

    .date select {
        margin-left: 2%;
        padding: 8px 4px;
        -webkit-appearance: none;
        background: url('/images/icons/arrow-down.svg') 13px -6px,
                    linear-gradient(to left, #839F22, #839F22 30px, #fff 30px);

        &:-ms-expand {
            display: none;
        }
    }

    .select {
        display: flex;
    }

    select {
        border-radius: 5px;
        background-color: white;
        border: 1px solid #DDDDDD;
    }

    .paymentTypeInputs label {
        display: flex;
        flex-direction: column;
        margin-left: 5%;
    }

    .radioBlock {
        display: flex;
        cursor: pointer;
        width: 100%;
        font-size: 12px;
    }

    .cashlessBlock {
        margin-bottom: 15px;
    }

    .cashless span:first-child {
        font-size: 16px;
    }

    .radioBlock * {
        cursor: pointer;
    }

    .personalDataAgreement {
        display: flex;
        align-items: center;
    }

    .personalDataAgreement p {
        margin-left: 10px;
        font-size: 14px;
    }

    .invalid {
        box-shadow: red 0 0 10px;
    }

    .text {
        font-size: 14px;
        margin-top: 10px;
    }

    span.uploadRequisites {
        align-self: flex-start;
        font-weight: bold;
        opacity: 1;
    }

    .commercialProposal {
        width: 100%;
        margin-bottom: 2%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .commercialProposal input {
        margin-left: 2%;
    }

    .fileForm {
        width: 55%;
        overflow: hidden;
    }

    .options {
        padding-right: 20px;
        text-align: end;
    }

    .chosenOption {
        background: url('/images/icons/green-check.svg') no-repeat;
        background-position: right;
    }

    .notChosenOption {
        background: url('/images/icons/red-cross.svg') no-repeat;
        background-position: right;
    }

    .headBackBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5%;
      padding-right: 4px;
    }

    .phone/deep/form > table > tbody > tr > td > input,
    .email/deep/form > table > tbody > tr > td > input,
    .name/deep/form > table > tbody > tr > td > input {
        background: #F7F7F7;
        color: black;
        border-radius: 5px;
    }

    .preloader {
      width: 32px;
      height: 32px;
      position: absolute;
      left: 85%;
      bottom: 40%;
    }
</style>
