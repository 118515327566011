const getters = {
    commonFiltersData(state, getters) {
        return {
            send_commercial_offer: getters.sendCommercialOffer,
            application_CRM: getters.addApplicationCRM,
            commercial_offer_CRM: getters.addCommercialOfferCRM,
            kp_type: 0,
            call_contact_person: 2,
            decision_maker_contacts: 1,
            work_with_objections: getters.workWithObjections,
            only_new_companies: 0,
            mobile_phone: 0,
            recording_of_call: 1,
            legal_form: 0,
            talk_script: 1,
            advanced_script: getters.expandScript,
            online_reporting: getters.onlineReport,
            call_type: 0,
            research_quests: 1,
            fields_for_actualization: 1,
            data_for_gathering_bd: 1,
            in_call_package: 0,
            purchased_from_export_base: getters.basePurchasedFromExportBaseForOrder,
            db_type: getters.dbType,
            client_db_type: 0,
            phone_number: 1,
            company_size: [],
            recall_firms: 0,
            work_with_agreements: getters.workWithAgreements,
            pay_for_leads: 0,
            tarification_type: 0,
            client_db_size: 0,
            non_standard_database: getters.baseSelection,
        };
    },

    additionalFilters(state, getters) {
        return JSON.stringify({
            sendCommercialOffer: getters.sendCommercialOffer,
            advancedScript: getters.expandScript,
            applicationCRM: getters.addApplicationCRM,
            onlineReporting: getters.onlineReport,
            nonStandardDatabase: getters.baseSelection,
            basePurchasedFromExportBase: getters.basePurchasedFromExportBaseForOrder,
            baseType: getters.dbType,
            commercialOfferCRM: getters.addCommercialOfferCRM,
        });
    },

    filtersData(state, getters) {
        return {
            ...getters['commonFiltersData'],
            count_firm_for_calling: getters.fixedCompaniesCountOfOrder,
        };
    },

    filtersDataOrderCalculation(state, getters) {
        return {
            ...getters['commonFiltersData'],
            count_firm_for_call: getters.fixedCompaniesCountOfOrder,
        };
    },

    // неизменяемый объект, передаваемый на сервер для совместимости со старым кодом на сервере
    lastConfigurationData() {
        return {
            total_count: 0,
            total_cost: 0,
            count_firm_for_calling: 0,
            one_firm_cost: 0,
        };
    },

    userInputData(state) {
        return {
            cin_user_phone: state.createOrder.userPhone,
            cin_user_email: state.createOrder.userEmail,
            cin_user_time_recall: state.createOrder.timeRecall,
            cin_user_time_recall_data: state.createOrder.timeRecallData,
            cin_user_comment: state.createOrder.userComment,
            cin_user_type_pay: state.createOrder.userTypePay,
            cin_user_requisiten: state.createOrder.filename,
            cin_user_timezone_offset: state.createOrder.timezoneOffset
        };
    },

    workWithAgreements(state) {
        return state.typeOfPromo === 'promo1500' ? 1 : 0;
    },

    workWithObjections(state) {
        return state.typeOfPromo === 'promo500' ? 0 : 1;
    },

    companiesCount(state, getters) {
        return getters.exportBase ? state.companiesCount : getters.fixedCompaniesCountOfOrder;
    },

    fixedCompaniesCountOfOrder(state) {
        switch (state.typeOfPromo) {
            case 'promo500' : return 500;
            case 'promo1000': return 1000;
            case 'promo1500': return 1500;
            default: return 0;
        }
    },

    costOfPromo(state) {
        switch (state.typeOfPromo) {
            case 'promo500' : return 27500;
            case 'promo1000': return 42500;
            case 'promo1500': return 58000;
            default: return 0;
        }
    },

    costOfClientBase(state) {
        switch (state.typeOfPromo) {
            case 'promo500' : return 2500;
            case 'promo1000': return 5000;
            case 'promo1500': return 7500;
            default: return 0;
        }
    },

    getOptions(state, getters) {
        return [
            getters.addApplicationCRM,
            getters.expandScript,
            getters.sendCommercialOffer,
            getters.onlineReport,
            getters.baseSelection,
            getters.addCommercialOfferCRM,
        ];
    },

    getOptionsForYourAndManualDatabase(state, getters) {
        return [
            getters.addApplicationCRM,
            getters.expandScript,
            getters.sendCommercialOffer,
        ];
    },

    totalCost(state, getters) {
        let totalCost = getters.costOfPromo + getters.additionalCost - getters.getSaleOptionCost;

        if (getters.clientBase) {
            totalCost += getters.costOfClientBase;
        }

        return totalCost;
    },

    additionalCost(state) {
        let cost = 0;

        for (const optionName in state.options.costs) {
            if (state.options[optionName]) {
                cost += state.options.costs[optionName];
            }
        }

        return cost;
    },

    getSaleOptionCost(state) {
        let cost = 0;
        let countOption = 0;
        let sale;

        for (const optionName in state.options.costs) {
            if (state.options[optionName]) {
                cost += state.options.costs[optionName];
                countOption += 1;
            }
        }

        let percentSale = 0;

        if (countOption === 2) {
            percentSale = 3;
        } else if (countOption === 3) {
            percentSale = 5;
        } else if (countOption >= 4) {
            percentSale = 10;
        }

        sale = cost / 100 * percentSale;

        return Math.round(sale / 100) * 100;
    },

    expandScript(state) {
        return state.options.expandScript ? 1 : 0;
    },

    onlineReport(state) {
        return state.options.onlineReport ? 1 : 0;
    },

    baseSelection(state) {
        return state.options.baseSelection ? 1 : 0;
    },

    sendCommercialOffer(state) {
      return state.options.commercialOffer ? 1 : 0;
    },

    addApplicationCRM(state) {
        return state.options.addApplicationCRM ? 1 : 0;
    },

    addCommercialOfferCRM(state) {
        return state.options.addCommercialOfferCRM ? 1 : 0;
    },

    calculationInProgress(state) {
        return state.calculationInProgress;
    },

    orderSettingsUpdated(state) {
        return state.orderSettingsUpdated;
    },

    typeOfPromo(state) {
        return state.typeOfPromo;
    },

    dbType(state, getters) {
        return (getters.exportBase && !getters.basePurchasedFromExportBase) ? 0 : 1;
    },

    exportBase(state) {
        return state.typeOfBase === 'exportBase';
    },
    basePurchasedFromExportBase(state) {
        return state.basePurchasedFromExportBase;
    },

    // для заказа в админке, для КП и для всей дальнейшей работы
    // опция имеет значение только если тип базы "exportBase"
    basePurchasedFromExportBaseForOrder(state, getters) {
        return (getters.exportBase && state.basePurchasedFromExportBase) ? 1: 0;
    },

    clientBase(state) {
        return state.typeOfBase === 'client';
    },

    baseToOrder(state) {
        return state.typeOfBase === 'baseToOrder';
    },

    getManualDatabase(state) {
        return state.manualDatabase;
    },

    getOurDatabase(state) {
        return state.ourDatabase;
    },

    getYourDatabase(state) {
        return state.yourDatabase;
    },

    allowSwitchOnlineReport(state, getters) {
        return state.typeOfBase === 'exportBase' && !getters.baseSelection;
    },

    allowSwitchBaseSelection(state, getters) {
        return state.typeOfBase !== 'exportBase' && !getters.onlineReport;
    },
};

export default getters;
