<template>
    <div class="app">
        <CitiesAndRubrics @changeModal="changeModal" :visible="advancedSections['citiesAndRubricsVisible']"/>
        <Options @changeModal="changeModal" :visible="advancedSections['optionsVisible']"/>
        <CreateOrder @changeModal="changeModal" :visible="advancedSections['createOrderVisible']"/>
        <Success/>
    </div>
</template>

<script>
    import CitiesAndRubrics from './components/Modals/CitiesAndRubrics/CitiesAndRubrics';
    import Options from './components/Modals/Options/Options';
    import CreateOrder from './components/Modals/CreateOrder/CreateOrder';
    import Success from './components/Modals/Success/Success';
    import {mapActions, mapState} from "vuex";

    export default {
        name: 'AdvancedPromo',

        components: {
            Success,
            CreateOrder,
            CitiesAndRubrics,
            Options,
        },

        computed: {
          ...mapState('sectionsNavigation', [
              'advancedSections',
              'introductionVisible',
          ])
        },

        methods: {
            ...mapActions('sectionsNavigation', [
                'hideSection',
                'openSection',
            ]),

            handleClick() {
                if (window.MGlobalSettings._filters_data.db_type === '0'
                  && Object.keys(window.MGlobalSettings._sections_data).length === 0) {
                    this.showFirstSlide();
                    this.allowTransition = true;
                } else {
                    window.$('.go_pay._popup').click();
                }
            },

            showFirstSlide() {
                window.MFunctions.openSimpleConfigurator();
            },

            changeModal(openingModal, closingModal) {
                this.openSection(openingModal);
                this.hideSection(closingModal);
                window.document.querySelector('.configurator_wrapper').style.minHeight = 0;

                if (openingModal === 'createOrder') {
                    window.MFunctions.reachMetrikaGoal('config');
                }
            },

            disallowTransition() {
                this.allowTransition = false;
            },
        },
    };
</script>

<style scoped>
    .app {
        font-style: normal;
        font-family: "PTSansExportBase", sans-serif;
    }

    img {
        cursor: pointer;
    }
</style>
