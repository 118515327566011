<template>
    <div @click="hideModal" class="closeImg">
        <img src="./_img/closeIcon.png" alt="close" width="30px" height="28px"/>
    </div>
</template>

<script>
export default {
    name: 'ClosingCross',

    methods: {
        hideModal() {
          this.$emit('hideModal');
        },
    },
};
</script>

<style scoped>
    .closeImg {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        transform: translateX(50%) translateY(-50%);
    }
</style>
