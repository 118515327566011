<template>
  <div class="commercialOfferButton">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'CommercialOfferButton',
  props: {
    text: {
      type: String,
      default: 'Получить Коммерческое',
      required: false,
    },
  },
};
</script>

<style scoped lang="less">
  .commercialOfferButton {
    display: inline-block;
    margin-top: 15px;
    background-color: #f79947;
    padding: 10px 20px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    z-index: 10;

    &:hover {
      box-shadow: 0 0 5px 3px white;
    }
  }
</style>
