import { defaultState } from './state';

const mutations = {
    setCompaniesCount(state, cnt) {
        state.companiesCount = cnt;
    },

    setFoundCompaniesCount(state, cnt) {
        state.foundCompaniesCount = cnt;
    },

    setCalculationInProgress(state, val) {
        state.calculationInProgress = val;
    },

    setOrderSettingsUpdated(state, val) {
        state.orderSettingsUpdated = val;
    },

    setTypeOfPackage(state, type) {
        state.typeOfPackage = type;
    },

    setDataBaseType(state, type) {
      state.dataBaseType = type;
    },

    setClientDbSize(state, size) {
        state.clientDbSize = size;
    },

    resetState (state) {
        Object.assign(state, defaultState);
    },

    resetDatabase(state) {
        state.ourDatabase = false;
        state.yourDatabase = false;
        state.manualDatabase = false;
    },

    setChoiceDatabase(state, [nameOfDatabase, stateOfDabase]) {
        state[nameOfDatabase] = stateOfDabase;
    },
};

export default mutations;
