<template>
    <div class="navigation">
        <Back :class="backClass" @back="back" :with-title="withTitle" />
        <Forward @forward="forward" :title="title" :disabled="disabledForward" :with-title="withTitle" />
    </div>
</template>

<script>
    import Back from "./Back/Back";
    import Forward from "./Forward/Forward";

    export default {
        name: "Navigation",
        components: {
            Back,
            Forward,
        },

        props: {
            withTitle: {
                type: Boolean,
                default: true,
            },
            title: {
                type: String,
                require: false,
                default: 'Далее',
            },
            disabledForward: {
                type: String,
                require: false,
                default: '',
            },
        },

        computed: {
            backClass() {
                return {
                    'back': true,
                    'withTitle': this.withTitle,
                };
            },
        },

        methods: {
            forward() {
                this.$emit('forward');
            },
            back() {
                this.$emit('back');
            },
        },
    }
</script>

<style scoped>
    .navigation {
        display: flex;
        align-items: center;
    }

    .back {
        margin-right: 10px;
    }

    .back.withTitle {
        margin-right: 15px;
    }
</style>
