<template>
  <main v-show="visible">
    <h1 class="title">Шаг 2. Выберите опции:</h1>
    <section class="head">
            <div class="info">
                <p>
                  При заказе, вы можете выбрать одну из продвинутых опций, для получения максимальной
                  эффективности обзвона, лучшей проработки и самой высокой конверсии звонков.
                </p>
            </div>
            <div class="navigation">
                <Navigation
                  class="navigation"
                  @forward="changeModal('createOrder', 'options')"
                  @back="changeModal('citiesAndRubrics', 'options')"
                />
            </div>
        </section>
    <section class="advancedSettingsBlock">
      <div class="settings">
        <p class="head">
          <CheckBox :class="[{'checkbox-button-label_partial': checkSelectedOptions}, 'check-all']"
                    v-if="!ourDatabase"
                    :chosen="checkAllOptions"
                    @click.native="switchAllOptions"
                    :mode="['dark']"
          />
          <span @click="switchAllOptions">PRO-пакет</span>&nbsp;опций к заказу:</p>
        <p class="options">
          <CheckBox :chosen="expandScript" @click.native="switchAdditionalOption('expandScript')" :mode="['dark']"/>
          <span @click="switchAdditionalOption('expandScript')">Расширенный скрипт разговора</span>
          <span @click="switchAdditionalOption('expandScript')">+2 500 руб.</span>
          <img src="./_img/question.png" width="16" height="16" alt="Пояснение"
               @mouseenter="openTooltip('expandScript')"
               @mouseleave="hideTooltip('expandScript')">
          <Tooltip :content="tooltipsTexts.expandScript"
                   :is-visible="tooltipsVisible.expandScript"
                   :position="'355px, 67px'"/>
        </p>
        <p class="options">
          <CheckBox :chosen="commercialOffer" @click.native="switchAdditionalOption('commercialOffer')" :mode="['dark']"/>
          <span @click="switchAdditionalOption('commercialOffer')">Ручная отправка КП</span>
          <span @click="switchAdditionalOption('commercialOffer')">+2 000 руб.</span>
        </p>
        <p :class="[{ hide: ourDatabase && onlineReport }, { disable: !exportBase },'options']">
          <CheckBox :chosen="baseSelection" @click.native="handleSwitchBaseSelection()" :mode="['dark']"/>
          <span @click="handleSwitchBaseSelection()">Ручной подбор базы данных</span>
          <span @click="handleSwitchBaseSelection()">+2 500 руб.</span>
          <img src="./_img/question.png" width="16" height="16" alt="Пояснение"
               @mouseenter="handleOpenTooltip('baseSelection')"
               @mouseleave="hideTooltip('baseSelection')"/>
          <Tooltip :content="tooltipsTexts.baseSelection"
                   :is-visible="tooltipsVisible.baseSelection"
                   :position="'336px, 67px'"/>
        </p>
        <p :class="[
            {
              disable: !exportBase || manualDatabase
            },
            {
              hide: baseSelection && ourDatabase
            },
            'options'
            ]
        ">
          <CheckBox :chosen="onlineReport" @click.native="switchAdditionalOption('onlineReport')"
                    :mode="['dark']"/>
          <span @click="switchAdditionalOption('onlineReport')">Онлайн-отчетность (мгновенно)</span>
          <span @click="switchAdditionalOption('onlineReport')">+1 500 руб.</span>
          <img src="./_img/question.png" width="16" height="16" alt="Пояснение"
               @mouseenter="handleOpenTooltip('onlineReport')"
               @mouseleave="hideTooltip('onlineReport')"
          />
          <Tooltip :content="tooltipsTexts.onlineReport"
                   :is-visible="tooltipsVisible.onlineReport"
                   :position="'356px, 103px'"/>
        </p>
        <p class="options">
          <CheckBox :chosen="addApplicationCRM" @click.native="switchAdditionalOption('addApplicationCRM')" :mode="['dark']"/>
          <span @click="switchAdditionalOption('addApplicationCRM')">Добавление «Заявки» в вашу CRM</span>
          <span :class="['additional-option', {'additional-option_active': addCommercialOfferCRM}]"
                @click="switchAdditionalOption('addCommercialOfferCRM')"
                @mouseenter="openTooltip('commercialOfferCRM')"
                @mouseleave="hideTooltip('commercialOfferCRM')"
          >
            + КП
          </span>
          <Tooltip :content="tooltipsTexts.commercialOfferCRM"
                   :is-visible="tooltipsVisible.commercialOfferCRM"
                   position="306px, 40px"
          />
          <span @click="switchAdditionalOption('addApplicationCRM')">+2 500 руб.</span>
          <img src="./_img/question.png" width="16" height="16" alt="Пояснение"
               @mouseenter="openTooltip('addApplicationCRM')"
               @mouseleave="hideTooltip('addApplicationCRM')">
          <Tooltip :content="tooltipsTexts.addApplicationCRM"
                   :is-visible="tooltipsVisible.addApplicationCRM"
                   :position="'421px, 114px'"/>
        </p>
      </div>
      <div class="settingsDescription">
        <p class="settingsDescription__text">
          Чем больше выбрано опций, тем выше скидка!
        </p>
      </div>
      <div class="totalCostBlock">
        <div class="descriptionPromo">
          <img src="./_img/cart.png" width="60" height="54" alt="">
          <div class="descriptionColumn">
            <p>Ваш пакет: {{ costOfPromo.toLocaleString('ru') }} р</p>
            <p>&#43;</p>
            <p v-if="additionalCost">
              <span v-if="getSaleOptionCost!==0" class="oldCostOptions">
                <s>{{ additionalCost.toLocaleString('ru') }}</s>
              </span>
              {{ additionalCost - getSaleOptionCost }} руб</p>
            <p v-else>пакета <i>PRO</i> нет</p>
          </div>
        </div>
        <div class="sumBlock">
          <p>Итого: <span class="sum">{{ totalCost.toLocaleString('ru') }} руб</span></p>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import Navigation from '../../Navigation/Navigation';
import CheckBox from 'checkbox';
import Tooltip from './Tooltip/Tooltip';
import TooltipTexts from './Tooltip/texts';

export default {
  name: 'Options',

  components: {
    Navigation,
    CheckBox,
    Tooltip,
  },

  props: {
    visible: Boolean,
  },

  data() {
    return {
      tooltipsVisible: {
        expandScript: false,
        onlineReport: false,
        baseSelection: false,
        addApplicationCRM: false,
        commercialOfferCRM: false,
      },

      tooltipsTexts: {
        expandScript: TooltipTexts.expandScript(),
        onlineReport: TooltipTexts.onlineReport(),
        baseSelection: TooltipTexts.baseSelection(),
        addApplicationCRM: TooltipTexts.addApplicationCRM(),
        commercialOfferCRM: TooltipTexts.commercialOfferCRM(),
      },
    };
  },

  methods: {
    changeModal(openingModal, closingModal) {
      this.$emit('changeModal', openingModal, closingModal);
    },

    switchAllOptions() {
      if (!this.allOptions) {
        this.resetAdditionalOptions();
      }

      if (this.yourDatabase) {
        this.switchAdditionalOption('expandScript');
        this.switchAdditionalOption('addApplicationCRM');
        this.switchAdditionalOption('commercialOffer');
      }

      if (this.manualDatabase) {
        this.switchAdditionalOption('expandScript');
        this.switchAdditionalOption('addApplicationCRM');
        this.switchAdditionalOption('commercialOffer');
      }

    },

    ...mapActions('advancedPromo/options',
        {
          switchAdditionalOption :'switchAdditionalOption',
        },
    ),

    ...mapMutations('advancedPromo/options', ['resetAdditionalOptions', 'setAdditionalOption']),

    openTooltip(tooltipName) {
      this.tooltipsVisible[tooltipName] = true;
    },

    hideTooltip(tooltipName) {
      this.tooltipsVisible[tooltipName] = false;
    },

    handleOpenTooltip(nameOfOptions) {
      if (nameOfOptions === 'onlineReport' && !this.baseSelection) {
          this.openTooltip(nameOfOptions);
      }

      if (nameOfOptions === 'baseSelection' && !this.onlineReport) {
          this.openTooltip('baseSelection');
      }
    },

    handleSwitchBaseSelection() {
        this.switchAdditionalOption('baseSelection');
    },
  },

  computed: {
    ...mapState('advancedPromo', [
        'typeOfPackage',
        'dataBaseType',
        'foundCompaniesCount',
        'yourDatabase',
        'ourDatabase',
        'manualDatabase',
    ]),

    ...mapState('advancedPromo/options', [
        'expandScript',
        'onlineReport',
        'baseSelection',
        'commercialOffer',
        'addApplicationCRM',
        'addCommercialOfferCRM',
        'allOptions',
    ]),

    ...mapGetters('advancedPromo', [
        'costOfPromo',
        'totalCost',
        'additionalCost',
        'minCountOfCalls',
        'getOptions',
        'getSaleOptionCost',
        'getOptionsForYourAndManualDatabase',
    ]),

    exportBase() {
      return this.dataBaseType === 'exportBase';
    },

    allowSwitchBaseSelection() {
      return this.foundCompaniesCount >= this.minCountOfCalls;
    },

    checkSelectedOptions() {
      if (this.allOptions) {
        return false;
      }
      return this.getOptions.some((option) => option === 1);
    },

    checkAllOptions() {
      if (this.getOptionsForYourAndManualDatabase.every((option) => option === 1) && !this.allOptions) {
        this.setAdditionalOption(['allOptions', true]);
      } else {
        this.setAdditionalOption(['allOptions', false]);
      }

      return this.allOptions;
    },
  },

  watch: {
    addApplicationCRM() {
      if (this.addApplicationCRM === false) {
        this.setAdditionalOption(['addCommercialOfferCRM', false]);
      }
    },

    addCommercialOfferCRM() {
      if (this.addCommercialOfferCRM) {
        this.setAdditionalOption(['addApplicationCRM', true]);
      }
    },
  }
};
</script>

<style scoped src="../_css/slide.css"></style>
<style scoped src="../_css/modals.css"></style>
<style scoped lang="less">
    main {
        margin: -15px 2% 0;
        font-size: 15px;
    }

    .navigation {
      position: absolute;
      right: 25px;
      top: 8.9%;
    }

    .info {
        padding-right: 40%;
    }

    .options/deep/.filter-card-form-line {
        margin-bottom: 22px;
    }

    .advancedSettingsBlock {
      display: grid;
      grid-template-areas: 'option description'
                            'option price';
      border-top: 1px solid;
      margin-top: 70px;
      padding: 0 0 2% 1%;
      row-gap: 20px;
      .settings {
        display: flex;
        flex-direction: column;
        flex-basis: 480px;
        grid-area: option;
        p {
          padding-left: 2%;
        }
        .head {
          font-weight: bold;
          width: 263px;
          background-color: #dad8d9;
          margin-top: -16px;
          span {
            background-color: #ffc100;
            padding: 4% 2%;
          }
        }
        .options.disable{
          display: none;

          & * {
            cursor: default !important;
          }
        }
        .options {
          display: flex;
          font-weight: bold;
          align-items: center;
          margin: 10px 0;
          span {
            margin-left: 15px;
            cursor: pointer;
          }

          img {
            margin-left: 10px;
            cursor: pointer;
          }

          span:last-of-type {
            color: #fff;
            background-color: #91ad2c;
            font-weight: 700;
            font-size: 13px;
            padding: 5px;
            margin-left: 4%;
            border-radius: 1px;
          }
        }
        .head {
          margin-bottom: 15px;
          -webkit-text-size-adjust: 100%;
          -moz-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
        }
      }
    }

    .additional-option{
      border: 2px solid gray;
      font-size: 12px;
      border-radius: 8px;
      padding: 5px;
      transition: .3s;

      &_active{
        background: #91ad2c;
        color: #ffff;
        border-color: transparent;
      }

      &:hover{
        background: #fff;
        color: black;
      }
    }

    .check-all{
      margin-right: 10px;
    }

    .advancedSettings/deep/.checkbox-button-label {
      display: inline-flex;
    }

    .hide {
      opacity: 0.3;

      & * {
        cursor: default !important;
      }
    }

    .oldCostOptions{
      font-size: 13px;
      margin-right: 3px;
    }

    .settingsDescription{
      grid-area: description;
      justify-self: center;
      align-self: flex-end;
      max-width: 300px;
      text-align: center;
      font-weight: bold;
    }

    .totalCostBlock {
      display: flex;
      flex-direction: column;
      background-color: #90ad2c;
      font-weight: bold;
      color: white;
      border-radius: 7px;
      font-size: 16px;
      top: 15px;
      align-self: center;
      grid-area: price;
      justify-self: center;
      width: 70%;
      .descriptionPromo {
        display: flex;
        width: 100%;
        padding: 6% 0 1%;
        justify-content: center;
        .descriptionColumn {
          text-align: center;
          margin-left: 3%;
        }
      }
      .sumBlock {
        width: 100%;
        border-top: 1px solid white;
        padding: 4% 0;
        p {
          margin-left: 11%;
        }
        .sum {
          margin-left: 25%;
        }
      }
    }

    @media (max-width: 800px) {
        .info {
            padding-right: 35%;
        }
    }
</style>
