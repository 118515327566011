<template>
    <div class="forward" @click="forward">
        <span v-if="withTitle" class="forwardTitle">
          {{ title }}
        </span>
        <img src="./_img/forward.png" class="forwardIcon" alt="Вперед" />
    </div>
</template>

<script>
    export default {
        name: "Forward",
        props: {
            withTitle: {
                type: Boolean,
                default: true,
            },
            title: {
              type: String,
              require: false,
              default: 'Далее',
            },
        },

        methods: {
            forward() {
                this.$emit('forward');
            },
        },
    }
</script>

<style scoped>
    .forward {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .forwardTitle {
        margin-right: 10px;
        font-size: 22px;
        font-style: italic;
        font-weight: bold;
        color: black;
        border-bottom: 1px dashed;
    }

    .forwardIcon {
        width: 48px;
    }
</style>
