const state = {
    // кол-во компаний по текущей конфигурации
    companiesCount: 500,
    foundCompaniesCount: 0,

    // идет расчет заказа?
    calculationInProgress: false,

    // обновлены настройки заказа во время расчета заказа?
    orderSettingsUpdated: false,

    // Тип пакета: low, medium, large, null (ничего не выбрано)
    typeOfPackage: null,

    // Тип базы: наша(exportBase) или заказчика(client).
    dataBaseType: 'exportBase',
    clientDbSize: 500,

    // Доп.стоимость звонка за обзвон по базе клиента.
    additionalCostByClientBase: 5,

    ourDatabase: true,
    yourDatabase: false,
    manualDatabase: false,
};

const defaultState = {
    companiesCount: 500,
    foundCompaniesCount: 0,
    typeOfPackage: 'medium',
    dataBaseType: 'exportBase',
    clientDbSize: 500
};

export { state, defaultState };
