const state = {
    // кол-во компаний по текущей конфигурации
    companiesCount: 0,

    // идет расчет заказа?
    calculationInProgress: false,

    // обновлены настройки заказа во время расчета заказа?
    orderSettingsUpdated: false,

    // Тип акции: promo500 или promo1000 или promo1500
    typeOfPromo: 'promo1000',

    // Тип базы: наша(exportBase) или заказчика(client), или на заказ(baseToOrder).
    typeOfBase: 'exportBase',

    // База была ранее куплена на export-base.ru (имеет значение только для Типа базы "exportBase")
    basePurchasedFromExportBase: false,

    ourDatabase: true,
    yourDatabase: false,
    manualDatabase: false,
};

const defaultState = {
    companiesCount: 0,
    typeOfPromo: 'promo1000',
    typeOfBase: 'exportBase',
    basePurchasedFromExportBase: false,
};

export { state, defaultState };
