<template>
        <main v-show="visible">
            <div class="introduction">
                <v-dialog/>
                <section class="promotions">
                    <Promotions @toNextStep="$emit('toNextStep')" />
                </section>
            </div>
        </main>
</template>

<script>
import Promotions from '../discountOrder/components/Modals/Promotions/Promotions';

export default {
    name: 'Introduction',

    props: {
        visible: Boolean,
        allowTransition: Boolean,
    },

    components: {
        Promotions,
    },
};
</script>

<style scoped src="../discountOrder/components/Modals/_css/slide.css"></style>
<style scoped src="../discountOrder/components/Modals/_css/modals.css"></style>
<style scoped src="../discountOrder/components/Modals/_css/modalTransition.css"></style>
<style scoped>
    section.head {
        margin-right: 50px;
        justify-content: flex-end;
    }
</style>
