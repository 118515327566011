import Vue from 'vue';
import Vuex from 'vuex';
import simplePromo from './modules/simplePromo/simplePromo';
import advancedPromo from './modules/advancedPromo/advancedPromo';
import sectionsNavigation from "./modules/sectionsNavigation/sectionsNavigation";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        simplePromo,
        advancedPromo,
        sectionsNavigation,
    },
});

export default store;
