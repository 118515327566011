<template>
<div class="baseOptionExportBase" :class="{disabled: !enabled}">
  <div class="option" :class="{chosen: !purchased}" @click="changePurchased(false)">
    <span class="tick"></span>
    <span class="name">выберите города и рубрики</span>
  </div>
  <div class="option" :class="{chosen: purchased}" @click="changePurchased(true)">
    <span class="tick"></span>
    <span class="name">скачана с <a @click="clickLink" href="https://export-base.ru" target="_blank">export-base.ru</a></span>
  </div>
</div>
</template>

<script>
export default {
  name: "BaseOptionExportBase",
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    purchased: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    changePurchased(newVal) {
      if (!this.enabled || this.purchased === newVal) {
        return;
      }
      this.$emit('changePurchased', newVal);
    },

    clickLink(e) {
        if (!this.enabled) {
            e.preventDefault();
            this.changePurchased(false);
        }
    },
  },
}
</script>

<style scoped lang="less">
.baseOptionExportBase {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:first-child {
    margin-bottom: .3em;
  }

  .tick {
    box-sizing: border-box;
    width: 1.3em;
    height: 1.3em;
    flex-shrink: 0;
    border: 2px solid #aaa;
    border-radius: 1.5em;
    margin-right: 0.3em;
    background: #B9B9B9;
  }
}

.option.chosen {
  cursor: default;
  .tick {
    border: 2px solid #889448;
    background: #9AA94C;
  }
}

.baseOptionExportBase.disabled {
  .option, option.chosen {
    cursor: inherit;
  }
}

</style>
