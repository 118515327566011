<template>
    <div class="app">
        <CitiesAndRubrics @changeModal="changeModal" :visible="discountSections['citiesAndRubricsVisible']"/>
        <Options @changeModal="changeModal" :visible="discountSections['optionsVisible']"/>
        <CreateOrder @changeModal="changeModal" :visible="discountSections['createOrderVisible']"/>
        <Success/>
    </div>
</template>

<script>
    import CitiesAndRubrics from './components/Modals/CitiesAndRubrics/CitiesAndRubrics';
    import Options from './components/Modals/Options/Options';
    import CreateOrder from './components/Modals/CreateOrder/CreateOrder';
    import Success from './components/Modals/Success/Success';
    import {mapState} from "vuex";

    export default {
        name: 'discountOrder',

        components: {
            Success,
            CreateOrder,
            CitiesAndRubrics,
            Options,
        },

        computed: {
          ...mapState('sectionsNavigation', [
              'discountSections',
              'introductionVisible',
          ])
        },

        methods: {
            handleClick() {
                if (window.MGlobalSettings._filters_data.db_type === '0'
                  && Object.keys(window.MGlobalSettings._sections_data).length === 0) {
                    this.showFirstSlide();
                    this.allowTransition = true;
                } else {
                    window.$('.go_pay._popup').click();
                }
            },

            showFirstSlide() {
                window.MFunctions.openSimpleConfigurator();
            },

            changeModal(openingModal, closingModal) {
                this.$emit('changeModal', openingModal, closingModal);
            },
        },
    };
</script>

<style scoped>
    .app {
        font-style: normal;
        font-family: "PTSansExportBase", sans-serif;
    }

    img {
        cursor: pointer;
    }
</style>
