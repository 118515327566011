<template>
    <modal name="success" @before-open="hideZIndex" @before-close="returnZIndex"
           classes="modalStyle" :width="400" height="auto" :scrollable="true">
        <section>
            <ClosingCross @hideModal="$modal.hide('success')"/>
            <p>Заявка для заказа по акции отправлена.<br>
                С вами свяжется менеджер и обсудит детали заказа.</p>
            <p class="infoEB">Если вы хотите скачать базу без проведения обзвона,<br> то можете сделать это на сайте
                <a href="http://export-base.ru/" target="_blank" class="blueLink">
                    export-base.ru
                </a>
            </p>
        </section>
    </modal>
</template>

<script>

import ClosingCross from '../../../../../../_common_components/ClosingCross/ClosingCross';

export default {

    name: 'Success',

    components: {
        ClosingCross,
    },

    methods: {
        hideZIndex() {
            this.$emit('before-open');
        },

        returnZIndex() {
            this.$emit('before-close');
        },
    }
};

</script>

<style scoped src="../_css/modals.css"></style>
<style scoped>
    section {
        margin: 2%;
    }

    p {
        margin: 3% 0;
    }

    .infoEB {
        opacity: 0.6;
    }

    .blueLink {
        color: #1B88C1;
    }
</style>
