<template>
  <div v-bind:class="[isVisible ? 'tooltipVisible' : '', 'tooltipDescriptionWrapper']"
       v-bind:style="positionClass">
    <div class="tooltipDescriptionArrow"></div>
    <div class="tooltipDescription">
      <div class="content" v-html="content"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Tooltip',

  props: {
    content: String,
    isVisible: Boolean,
    position: String
  },

  data() {
    return {
      positionClass: {
        transform: `translate(${this.position})`
      },
    };
  },
};
</script>

<style scoped lang="less">
  .tooltipDescriptionWrapper {
    position: absolute;
    max-width: 400px;
    bottom: 37px;
    line-height: 1.2;
    left: -100px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: all 0s ease 0s;
    font-weight: normal;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    .tooltipDescriptionArrow {
      position: absolute;
      background: url('_img/arrow.png') no-repeat;
      height: 7px;
      top: 32%;
      width: 15px;
      left: -10px;
      transform: rotate(-90deg)
    }
    .tooltipDescription{
      padding: 7px;
      border-radius: 4px;
      color: white;
      background: #424242;
      .content {
        font-style: italic;
        font-weight: normal;
      }
    }
  }
  .tooltipVisible {
    opacity: 1;
    visibility: visible;
    transition: all .3s ease .3s;
  }
</style>
