import api from '../../../../../components/discountOrder/api/api';

const getDefaultState = {
    userPhone: '',
    userName: 'НеЗаполнено',
    userEmail: '',
    userComment: '',
    userTypePay: 1,
};

export default {
    namespaced: true,

    state: {
        userPhone: '',
        userName: 'НеЗаполнено',
        userEmail: '',
        timeRecall: '',
        timezoneOffset: 0,
        timeRecallData: '',
        userComment: '',
        userTypePay: 1,
        filename: '',
        dateSelectors: [],
        timeSelectors: [],
    },

    mutations: {
        setDateSelectors(state, dateSelectors) {
            state.dateSelectors = dateSelectors;
        },

        setTimeSelectors(state, timeSelectors) {
            state.timeSelectors = timeSelectors;
        },

        setUserPhone(state, phone) {
            state.userPhone = phone;
        },

        setUserName(state, name) {
            state.userName = name;
        },

        setUserEmail(state, email) {
            state.userEmail = email;
        },

        setUserComment(state, comment) {
            state.userComment = comment;
        },

        setUserTypePay(state, typePay) {
            state.userTypePay = typePay;
        },

        setTimeRecall(state, timeRecall) {
            state.timeRecall = timeRecall;
        },
        
        setTimezoneOffset(state) {
            const localTime = new Date();

            //  Смещение относительно Московского часового пояса будет положительным для местного часового пояса,
            //  находящегося западнее часового пояса UTC и отрицательным — восточнее (поэтому минус перед скобкой).
            //  И вычитаем три, чтобы получить смещение относительно Москвы.
            state.timezoneOffset = - (localTime.getTimezoneOffset() / 60) - 3;
        },
        
        setTimeRecallData(state, timeRecallData) {
            state.timeRecallData = timeRecallData;
        },

        setFileName(state, filename) {
            state.filename = filename;
        },

        resetState (state) {
            Object.assign(state, getDefaultState);
        },
    },

    actions: {
        getDateAndTimeSelector(context) {
            return api.getDateAndTimeSelector(context.state.timezoneOffset)
                .then(dateAndTimeSelectors => {
                    context.commit('setDateSelectors', dateAndTimeSelectors.dateSelector);
                    context.commit('setTimeSelectors', dateAndTimeSelectors.timeSelector);
                });
        },
    },
};
