<template>
<div :class="[{ chosen: chosen }, 'baseOption']" @click="choose">
  <span class="title">{{ title }}</span>
    <span class="price">{{ footnote }}</span>
  <span class="body">
    <slot name="body"></slot>
  </span>
</div>

</template>

<script>
export default {
  name: "BaseOption",
  props: {
    chosen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    footnote: {
      type: String,
      required: true,
    },
  },

  methods: {
    choose() {
      if (this.chosen) {
        return;
      }
      this.$emit('choose');
    }
  },
}
</script>

<style scoped lang="less">
.baseOption {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  min-height: 100px;
  padding: 5px 10px 10px 10px;
  border-radius: 5px;
  border: 1px solid #DDDDDD;
  cursor: pointer;

  &:hover {
      border: 1px solid #839F22;
  }

  .title {
    font-weight: bold;
  }

  .body {
    line-height: 1;
    margin-top: 0.6em;
    font-size: 12px;
  }

  .price {
    font-weight: bold;
    font-size: 20px;
    color: var(--primaryEB, #839F22);
  }
}

.baseOption.chosen {
  background: var(--primaryEB, #839F22);
  color: white;
  cursor: default;

  .price {
    color: white;
  }
}
</style>
