<template>
  <main v-show="visible">
    <section class="head">
        <div>
            <h1 class="title">Шаг 2. Выберите опции:</h1>
            <div class="info">
                <p>
                    При заказе, вы можете выбрать одну из продвинутых опций, для получения максимальной
                    эффективности обзвона, лучшей проработки и самой высокой конверсии звонков.
                </p>
            </div>
        </div>
        <div>
            <div class="navigation">
                <Navigation
                    class="navigation"
                    @forward="changeModal('createOrder', 'options')"
                    @back="changeModal('citiesAndRubrics', 'options')"
                />
            </div>
            <div class="settingsDescription">
                <p class="settingsDescription__text">
                    Чем больше выбрано опций, тем выше скидка!
                </p>
            </div>
        </div>
    </section>
    <section class="advancedSettingsBlock">
        <div class="proPacage">
            <p class="head">
                <span @click="switchAllOptions">"PRO-пакет"</span>&nbsp;опций к заказу:
            </p>
          <div class="settings">
            <div class="options">
                <div class="option">
                    <CheckBox :chosen="expandScript" @click.native="switchAdditionalOption('expandScript')" :mode="['dark']"/>
                    <span @click="switchAdditionalOption('expandScript')">Расширенный скрипт разговора</span>
                    <img src="/images/icons/info.svg" width="16" height="16" alt="Пояснение"
                         @mouseenter="openTooltip('expandScript')"
                         @mouseleave="hideTooltip('expandScript')">
                    <Tooltip :content="tooltipsTexts.expandScript"
                             :is-visible="tooltipsVisible.expandScript"
                             :position="'355px, 123px'"/>
                </div>
                <span @click="switchAdditionalOption('expandScript')" class="optionPrice">+2 500 &#8381;</span>
            </div>
            <div class="options">
                <div class="option">
                  <CheckBox :chosen="commercialOffer" @click.native="switchAdditionalOption('commercialOffer')" :mode="['dark']"/>
                  <span @click="switchAdditionalOption('commercialOffer')">Ручная отправка КП</span>
                </div>
              <span @click="switchAdditionalOption('commercialOffer')" class="optionPrice">+2 000 &#8381;</span>
            </div>
            <div :class="[{ hide: ourDatabase && onlineReport }, { disable: !exportBase },'options']">
                <div class="option">
                  <CheckBox :chosen="baseSelection" @click.native="handleSwitchBaseSelection()" :mode="['dark']"/>
                  <span @click="handleSwitchBaseSelection()">Ручной подбор базы данных</span>
                    <img src="/images/icons/info.svg" width="16" height="16" alt="Пояснение"
                         @mouseenter="handleOpenTooltip('baseSelection')"
                         @mouseleave="hideTooltip('baseSelection')"/>
                    <Tooltip :content="tooltipsTexts.baseSelection"
                             :is-visible="tooltipsVisible.baseSelection"
                             :position="'336px, 67px'"/>
                </div>
              <span @click="handleSwitchBaseSelection()" class="optionPrice">+2 500 &#8381;</span>
            </div>
            <div :class="[
                {
                  disable: !exportBase || manualDatabase
                },
                {
                  hide: baseSelection && ourDatabase
                },
                'options'
                ]
            ">
                <div class="option">
                    <CheckBox :chosen="onlineReport" @click.native="switchAdditionalOption('onlineReport')"
                            :mode="['dark']"/>
                    <span @click="switchAdditionalOption('onlineReport')">Онлайн-отчетность (мгновенно)</span>
                    <img src="/images/icons/info.svg" width="16" height="16" alt="Пояснение"
                       @mouseenter="handleOpenTooltip('onlineReport')"
                       @mouseleave="hideTooltip('onlineReport')"
                    />
                    <Tooltip :content="tooltipsTexts.onlineReport"
                           :is-visible="tooltipsVisible.onlineReport"
                           :position="'355px, 145px'"/>
                </div>
                <span @click="switchAdditionalOption('onlineReport')" class="optionPrice">+1 500 &#8381;</span>
            </div>
            <div class="options">
                <div class="option">
                    <CheckBox :chosen="addApplicationCRM" @click.native="switchAdditionalOption('addApplicationCRM')" :mode="['dark']"/>
                    <span @click="switchAdditionalOption('addApplicationCRM')">Добавление «Заявки» в вашу CRM</span>
                    <span :class="['additional-option', {'additional-option_active': addCommercialOfferCRM}]"
                        @click="switchAdditionalOption('addCommercialOfferCRM')"
                        @mouseenter="openTooltip('commercialOfferCRM')"
                        @mouseleave="hideTooltip('commercialOfferCRM')"
                    >
                    +КП
                    </span>
                    <Tooltip :content="tooltipsTexts.commercialOfferCRM"
                           :is-visible="tooltipsVisible.commercialOfferCRM"
                           position="385px, 60px"
                    />
                    <img src="/images/icons/info.svg" width="16" height="16" alt="Пояснение"
                         @mouseenter="openTooltip('addApplicationCRM')"
                         @mouseleave="hideTooltip('addApplicationCRM')">
                    <Tooltip :content="tooltipsTexts.addApplicationCRM"
                             :is-visible="tooltipsVisible.addApplicationCRM"
                             :position="'413px, 143px'"/>
                </div>
              <span @click="switchAdditionalOption('addApplicationCRM')" class="optionPrice">+2 500 &#8381;</span>
            </div>
          </div>
      </div>
      <div class="totalCostBlock">
        <div class="descriptionPromo">
          <img src="/images/icons/cart.svg" width="60" height="54" alt="">
          <div class="descriptionColumn">
            <p>Ваш пакет: <b>{{ costOfPromo.toLocaleString('ru') }} руб.</b></p>
            <p>Пакет PRO: </p>
            <p v-if="additionalCost">
              <span v-if="getSaleOptionCost!==0" class="oldCostOptions">
                <s>{{ additionalCost.toLocaleString('ru') }}</s>
              </span>
              <b>{{ additionalCost - getSaleOptionCost }} руб.</b></p>
            <p v-else>не выбран</p>
          </div>
        </div>
        <div class="sumBlock">
          <p>Итого: {{ totalCost.toLocaleString('ru') }} руб</p>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import Navigation from '../../Navigation/Navigation';
import CheckBox from 'checkbox';
import Tooltip from './Tooltip/Tooltip';
import TooltipTexts from './Tooltip/texts';

export default {
  name: 'Options',

  components: {
    Navigation,
    CheckBox,
    Tooltip,
  },

  props: {
    visible: Boolean,
  },

  data() {
    return {
      tooltipsVisible: {
        expandScript: false,
        onlineReport: false,
        baseSelection: false,
        addApplicationCRM: false,
        commercialOfferCRM: false,
      },

      tooltipsTexts: {
        expandScript: TooltipTexts.expandScript(),
        onlineReport: TooltipTexts.onlineReport(),
        baseSelection: TooltipTexts.baseSelection(),
        addApplicationCRM: TooltipTexts.addApplicationCRM(),
        commercialOfferCRM: TooltipTexts.commercialOfferCRM(),
      },
    };
  },

  methods: {
    changeModal(openingModal, closingModal) {
      this.$emit('changeModal', openingModal, closingModal);
    },

    switchAllOptions() {
      if (!this.allOptions) {
        this.resetAdditionalOptions();
      }

      if (this.yourDatabase) {
        this.switchAdditionalOption('expandScript');
        this.switchAdditionalOption('addApplicationCRM');
        this.switchAdditionalOption('commercialOffer');
      }

      if (this.manualDatabase) {
        this.switchAdditionalOption('expandScript');
        this.switchAdditionalOption('addApplicationCRM');
        this.switchAdditionalOption('commercialOffer');
      }

    },

    ...mapActions('advancedPromo/options',
        {
          switchAdditionalOption :'switchAdditionalOption',
        },
    ),

    ...mapMutations('advancedPromo/options', ['resetAdditionalOptions', 'setAdditionalOption']),

    openTooltip(tooltipName) {
      this.tooltipsVisible[tooltipName] = true;
    },

    hideTooltip(tooltipName) {
      this.tooltipsVisible[tooltipName] = false;
    },

    handleOpenTooltip(nameOfOptions) {
      if (nameOfOptions === 'onlineReport' && !this.baseSelection) {
          this.openTooltip(nameOfOptions);
      }

      if (nameOfOptions === 'baseSelection' && !this.onlineReport) {
          this.openTooltip('baseSelection');
      }
    },

    handleSwitchBaseSelection() {
        this.switchAdditionalOption('baseSelection');
    },
  },

  computed: {
    ...mapState('advancedPromo', [
        'typeOfPackage',
        'dataBaseType',
        'foundCompaniesCount',
        'yourDatabase',
        'ourDatabase',
        'manualDatabase',
    ]),

    ...mapState('advancedPromo/options', [
        'expandScript',
        'onlineReport',
        'baseSelection',
        'commercialOffer',
        'addApplicationCRM',
        'addCommercialOfferCRM',
        'allOptions',
    ]),

    ...mapGetters('advancedPromo', [
        'costOfPromo',
        'totalCost',
        'additionalCost',
        'minCountOfCalls',
        'getOptions',
        'getSaleOptionCost',
        'getOptionsForYourAndManualDatabase',
    ]),

    exportBase() {
      return this.dataBaseType === 'exportBase';
    },

    allowSwitchBaseSelection() {
      return this.foundCompaniesCount >= this.minCountOfCalls;
    },

    checkSelectedOptions() {
      if (this.allOptions) {
        return false;
      }
      return this.getOptions.some((option) => option === 1);
    },

    checkAllOptions() {
      if (this.getOptionsForYourAndManualDatabase.every((option) => option === 1) && !this.allOptions) {
        this.setAdditionalOption(['allOptions', true]);
      } else {
        this.setAdditionalOption(['allOptions', false]);
      }

      return this.allOptions;
    },
  },

  watch: {
    addApplicationCRM() {
      if (this.addApplicationCRM === false) {
        this.setAdditionalOption(['addCommercialOfferCRM', false]);
      }
    },

    addCommercialOfferCRM() {
      if (this.addCommercialOfferCRM) {
        this.setAdditionalOption(['addApplicationCRM', true]);
      }
    },
  }
};
</script>

<style scoped src="../_css/modals.css"></style>
<style scoped lang="less">


    .info {
        padding-right: 10%;
    }

    section.head {
        display: flex;
        justify-content: space-between;

        .title {
            margin-bottom: 10px;
            font-size: 24px;
            font-weight: bold;
        }

        .info {
            font-size: 14px;
        }

        .navigation {
            justify-content: flex-end;
        }

        .settingsDescription{
            margin-top: 15px;
            font-size: 14px;
            grid-area: description;
            justify-self: center;
            align-self: flex-end;
            max-width: 300px;
            text-align: center;
        }
    }

    .options/deep/.filter-card-form-line {
        margin-bottom: 22px;
    }

    .advancedSettingsBlock {
      display: flex;
      gap: 50px;
      margin-top: 30px;
      justify-content: space-around;
      align-items: flex-end;

        .proPacage {

            .head {
                font-weight: bold;
                font-size: 20px;
                span {
                    color: #91ad2c;
                }
            }
        }


      .settings {
          display: flex;
          flex-direction: column;
          gap: 15px;
          border: 1px solid #DDDDDD;
          border-radius: 5px;
          font-size: 14px;
          padding: 10px;
          margin-top: 20px;

        p {
          padding-left: 2%;
        }
        .head {
          font-weight: bold;
          margin-top: 16px;
        }
        .options.disable{
          display: none;

          & * {
            cursor: default !important;
          }
        }
        .options {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

            .option {
                display: flex;
                align-items: center;
            }
          span {
            margin-left: 10px;
            cursor: pointer;
              white-space: nowrap;
          }

          img {
            margin-left: 10px;
            cursor: pointer;
          }

          .optionPrice {
            color: #91ad2c;
            font-weight: 700;
          }
        }
        .head {
          margin-bottom: 15px;
          -webkit-text-size-adjust: 100%;
          -moz-text-size-adjust: 100%;
          -ms-text-size-adjust: 100%;
        }
      }
    }

    .additional-option{
        border: 1px solid #DDDDDD;
      border-radius: 5px;
      padding: 2px;
      transition: .3s;

      &_active{
        background: #91ad2c;
        color: #ffff;
        border-color: transparent;
      }

      &:hover{
          border-color: #91ad2c;
        background: #fff;
        color: black;
      }
    }

    .check-all{
      margin-right: 10px;
    }

    .advancedSettings/deep/.checkbox-button-label {
      display: inline-flex;
    }

    .hide {
      opacity: 0.3;

      & * {
        cursor: default !important;
      }
    }

    .oldCostOptions{
      font-size: 13px;
      margin-right: 3px;
    }

    .totalCostBlock {
      display: flex;
      flex-direction: column;
        border: 1px solid var(--primaryEB, #839F22);
      border-radius: 5px;
      font-size: 16px;
      justify-self: center;

      .descriptionPromo {
        display: flex;
        padding: 10px;
        justify-content: center;
        .descriptionColumn {
          text-align: center;
          margin-left: 20px;
        }
      }
      .sumBlock {
          background-color: #91ad2c;
          border-radius: 5px;
          padding: 10px;
          color: white;
          font-weight: bold;
      }
    }

    @media (max-width: 800px) {
        .info {
            padding-right: 35%;
        }
    }
</style>
