<template>
  <div class="call-button-prime">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'CommercialOfferButton',
  props: {
    text: {
      type: String,
      default: 'Получить Коммерческое',
      required: false,
    },
  },
};
</script>
