<template>
    <section class="promotionsBlock">
        <div class="promotionNavigation">
            <div id="stocksButton" class="call-button-prime" @click="showStocks">Акции</div>
            <div id="tariffsButton" class="call-button-secondary"@click="showTariffs">Тарифы</div>
        </div>
        <div id="promotionStocks" class="promotion-stocks">
            <Promotion :typeOfPromo="typeOfPromo" @mouseover.native="reducePromoHeight"
                                                  @mouseout.native="restorePromoHeight"
                       countForCalling="500"
                       oldPrice="32 800"
                       newPrice="29 500"
                       discount="10"
                       mode="small"
                       :chosen="typeOfPromo === 'promo500'"
                       @changeTypeOfPromo="choseTypeOfPromo('promo500')"
                       @toNextStep="$emit('toNextStep')">
            </Promotion>
            <Promotion :typeOfPromo="typeOfPromo"
                       countForCalling="1000"
                       oldPrice="62 000"
                       newPrice="46 500"
                       discount="25"
                       mode="big"
                       :chosen="typeOfPromo === 'promo1000'"
                       :reducePromoHeight="reducePromo"
                       @changeTypeOfPromo="choseTypeOfPromo('promo1000')"
                       @toNextStep="$emit('toNextStep')">
              Высокая конверсия! <br>
              Оптимальный выбор для проведения любого обзвона.
            </Promotion>
            <Promotion :typeOfPromo="typeOfPromo" @mouseover.native="reducePromoHeight"
                                                  @mouseout.native="restorePromoHeight"
                       countForCalling="1500"
                       oldPrice="93 000"
                       newPrice="65 000"
                       discount="30"
                       mode="small"
                       :chosen="typeOfPromo === 'promo1500'"
                       @changeTypeOfPromo="choseTypeOfPromo('promo1500')"
                       @toNextStep="$emit('toNextStep')">
            </Promotion>
        </div>
        <div id="promotionTariffs" class="promotion-tariffs" style="display:none;">
            <PromotionPRO :typeOfPackage="typeOfPackage" @mouseover.native="reducePromoHeight"
                   @mouseout.native="restorePromoHeight"
                   oneCallCost="39"
                   price="19 500"
                   mode="small"
                   :chosen="typeOfPackage === 'low'"
                   @changeTypeOfPackage="choseTypeOfPackage('low')"
                   @toNextStep="$emit('toNextStep')">
            </PromotionPRO>
            <PromotionPRO :typeOfPackage="typeOfPackage"
                   oneCallCost="60"
                   price="30 000"
                   mode="big"
                   :chosen="typeOfPackage === 'medium'"
                   :reducePromoHeight="reducePromo"
                   @changeTypeOfPackage="choseTypeOfPackage('medium')"
                   @toNextStep="$emit('toNextStep')">
            </PromotionPRO>
            <PromotionPRO :typeOfPackage="typeOfPackage" @mouseover.native="reducePromoHeight"
                       @mouseout.native="restorePromoHeight"
                       oneCallCost="65"
                       price="32 500"
                       mode="small"
                       :chosen="typeOfPackage === 'large'"
                       @changeTypeOfPackage="choseTypeOfPackage('large')"
                       @toNextStep="$emit('toNextStep')">
            </PromotionPRO>
        </div>
    </section>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import Promotion from '../Promotion/Promotion';
import PromotionPRO from '../../../../advancedPromo/components/Modals/Promotion/Promotion';

export default {
    name: 'Promotions',

    data() {
        return {
            reducePromo: false,
        };
    },

    components: {
        Promotion,
        PromotionPRO,
    },

    methods: {
        ...mapMutations('simplePromo', [
            'setTypeOfPromo',
        ]),
        ...mapMutations('advancedPromo', [
            'setTypeOfPackage',
        ]),
        choseTypeOfPromo(typeOfPromo) {
            this.setTypeOfPromo(typeOfPromo);
            this.setTypeOfPackage(null); // Сбрасываем пакеты ПРО, потому что может быть выбран только один пакет
        },

        choseTypeOfPackage(typeOfPackage) {
            this.setTypeOfPackage(typeOfPackage);
            this.setTypeOfPromo(null);
        },

        reducePromoHeight() {
            this.reducePromo = true;
        },

        restorePromoHeight() {
            this.reducePromo = false;
        },

        showTariffs() {
            document.getElementById('promotionStocks').style.display = 'none';
            document.getElementById('promotionTariffs').style.display = 'flex';
            document.getElementById('stocksButton').className = 'call-button-secondary';
            document.getElementById('tariffsButton').className = 'call-button-prime';
        },

        showStocks() {
          document.getElementById('promotionStocks').style.display = 'flex';
          document.getElementById('promotionTariffs').style.display = 'none';
          document.getElementById('stocksButton').className = 'call-button-prime';
          document.getElementById('tariffsButton').className = 'call-button-secondary';
        },
    },

    computed: {
        ...mapGetters('simplePromo',[
          'typeOfPromo',
        ]),
        ...mapGetters('advancedPromo', [
            'typeOfPackage',
        ])
    },
};
</script>

<style scoped src="../_css/slide.css"></style>
<style scoped>
    .promotionsBlock {
        display: flex;
        justify-content: space-evenly;
        position: relative;
        z-index: 50;
        margin: 20px 0
    }

    .promotionNavigation {
      position: absolute;
      top: -52px;
      display: flex;
      gap: 20px;
    }

    .promotion-stocks, .promotion-tariffs{
        display: flex;
        gap: 15px;
    }
</style>
